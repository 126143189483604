import { bucketS3, caminhoAnexosComponentsInteraction } from 'constants/EnvironmentData';
import { ComponentCenarioGlobal } from 'models/componentInteraction/ComponentCenarioGlobal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';
import Colors from 'enums/Colors';
import { FiSend } from 'react-icons/fi';
import { BiSolidMessageAltDetail } from 'react-icons/bi';
import { OutlineButton } from 'components/UI/Buttons';

const Styled = {
    Container: styled.div<{ img: string }>`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 400px;
        margin-bottom: 22px;
        padding: 22px;
        background-image: url(${props => props.img});
        background-size: cover; /* Ajusta o tamanho da imagem */
        background-position: center; /* Centraliza a imagem */
        background-repeat: no-repeat;
        border-radius: 20px;
    `,

    TitleQuestion: styled.h1`
        font-size: 22px;
        font-weight: 700;
        color: ${Colors.Blue};
    `,
    TextQuestion: styled.h1`
        display: flex;
        align-self: end;
        justify-self: end;
        font-size: 14px;
        font-weight: 600;
        color: ${Colors.Blue};
    `,

    ContentPergunta: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        width: 80%;
        padding: 8px;
        background-color: #fff;
    `,

    AltContent: styled.div<{ isFading: boolean }>`
        display: flex;
        padding: 10px;
        font-size: 18px;
        background-color: #f5f5f5;
        margin-top: 22px;
        align-items: center;
        transition: opacity 0.5s, transform 0.5s;
        line-height: 28px;
        flex-direction: row-reverse;
        justify-content: space-between;
        background-color: #d9fdd3;
        gap: 8px;
        cursor: pointer;
        opacity: ${props => (props.isFading ? 0 : 1)};
        transform: ${props => (props.isFading ? 'translateY(-20px)' : 'none')};
        &:hover {
            transform: translateY(-10px);
        }
    `,
    AltContentStatic: styled.div`
        display: flex;
        padding: 10px;
        font-size: 18px;
        background-color: #f5f5f5;
        margin-top: 22px;
        align-items: center;
        transition: opacity 0.5s, transform 0.5s;
        line-height: 28px;
        flex-direction: row-reverse;
        justify-content: space-between;
        background-color: #d9fdd3;
        gap: 8px;
       
    `,

    Feedback: styled.div<{ isAppearing: boolean }>`
        width: 80%;
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        gap: 12px;
        opacity: ${props => (props.isAppearing ? 1 : 0)};
        transition: opacity 0.5s;
        padding: 8px;
        margin-top: 22px;
        border-radius: 20px 20px 20px 0px;
    `,
};

interface IComponentCenarioPublic {
    dados: ComponentCenarioGlobal;
}

const ComponentCenarioPublic = ({ dados }: IComponentCenarioPublic) => {
    const [indexCenario, setIndexCenario] = useState<number>(0);
    const [exibeFeedback, setExibeFeedback] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>('');
    const [respSelected, setRespSelected] = useState<string>('');
    const [isFading, setIsFading] = useState<boolean>(false); // Para animação de fade-out nas alternativas
    const [isAppearing, setIsAppearing] = useState<boolean>(false); // Para animação de fade-in no feedback

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosComponentsInteraction();
    };

    const isFinishForm = (): boolean => {
        const quantityCenario = dados.cenarios.length;
        return indexCenario + 1 === quantityCenario;
    };

    const nextCenario = () => {
        const quantityCenario = dados.cenarios.length;

        if (indexCenario + 1 < quantityCenario) {
            setIndexCenario(indexCenario + 1);
            setFeedback('');
            setExibeFeedback(false);
        }
    };

    const resetForm = () => {
        setFeedback('');
        setExibeFeedback(false);
        setIndexCenario(0);
    };

    const Feedback = () => {
        return (
            <Styled.Feedback isAppearing={isAppearing}>
                <Styled.TitleQuestion>
                    <BiSolidMessageAltDetail />
                </Styled.TitleQuestion>
                <TextDangerouslySetInnerHtml style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: feedback }} />
                {isFinishForm() ? (
                    <OutlineButton onClick={resetForm}>Refazer</OutlineButton>
                ) : (
                    <OutlineButton onClick={nextCenario}>Avançar</OutlineButton>
                )}
            </Styled.Feedback>
        );
    };

    const handleAlternativeClick = (altFeedback: string, resp: string) => {
        setIsFading(true); // Inicia a animação de fade-out das alternativas
        setTimeout(() => {
            setExibeFeedback(true);
            setFeedback(altFeedback);
            setIsFading(false); // Reseta o estado após a animação
            setIsAppearing(true); // Inicia a animação de fade-in do feedback
            setRespSelected(resp);
        }, 500); // Tempo deve ser o mesmo da transição de opacity
    };

    return (
        <Styled.Container img={getS3BaseUrl() + dados.anexo.filepath}>
            {dados.cenarios.map((c, i) => {
                if (i === indexCenario) {
                    return (
                        <>
                            {exibeFeedback ? (
                                <div
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', width: '80%' }}
                                >
                                    <Styled.AltContentStatic
                                        style={{ borderRadius: '20px 20px 0px 20px' }}
                                    >
                                        <FiSend size={18} color={Colors.Blue} />
                                        {respSelected}
                                    </Styled.AltContentStatic>
                                </div>
                            ) : (
                                <Styled.ContentPergunta style={{ borderRadius: exibeFeedback ? '20px 20px 0px 20px' : '20px 20px 20px 0px' }}>
                                    <TextDangerouslySetInnerHtml
                                        dangerouslySetInnerHTML={{ __html: exibeFeedback ? respSelected : c.title }}
                                        style={{ marginTop: 12 }}
                                    />
                                </Styled.ContentPergunta>
                            )}

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', width: '80%' }}>
                                {!exibeFeedback &&
                                    c.alternativas.map((alt, i) => (
                                        <Styled.AltContent
                                            key={i}
                                            isFading={isFading} // Passa o estado de fading
                                            onClick={() => handleAlternativeClick(alt.feedback, alt.text)}
                                            style={{ borderRadius: exibeFeedback ? '20px 20px 20px 0px' : '20px 20px 0px 20px' }}
                                        >
                                            <FiSend size={18} color={Colors.Blue} />
                                            {alt.text}
                                        </Styled.AltContent>
                                    ))}
                            </div>

                            {exibeFeedback && <Feedback />}
                        </>
                    );
                }
                return null; // Adiciona um retorno para evitar warnings
            })}
        </Styled.Container>
    );
};

export default ComponentCenarioPublic;
