import React, { useEffect, useState } from 'react';
import ComponentQuizModel from 'models/componentInteraction/ComponentQuiz';
import Styled from '../../RecursosConteudoPage.styled';
import type { CheckboxProps, RadioChangeEvent } from 'antd';
import styled from 'styled-components';
import { Button, Checkbox, ConfigProvider, Divider, Progress, Radio } from 'antd';
import type { ProgressProps } from 'antd';
import Colors from 'enums/Colors';
import ComponentQuizAlternativasModel from 'models/componentInteraction/ComponentQuizAlternativas';
import { FaCheck } from 'react-icons/fa';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';

const enum FeedbackEnum {
    NENHUM = 'NENHUM',
    CORRECT = 'CORRECT',
    CORRECT_NOT_SELECTED = 'CORRECT_NOT_SELECTED',
    ERROR = 'ERROR',
}

const StyleQuiz = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 6px;
        border-radius: 10px;
        border: 1px solid #e9ecef;
        margin-bottom: 20px;
    `,

    Alternativa: styled.div<{ feedback: FeedbackEnum }>`
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 18px;
        min-height: 50px;
        padding-left: 10px;
        margin-bottom: 10px;

        border: ${({ feedback }) =>
                feedback === FeedbackEnum.CORRECT || feedback === FeedbackEnum.CORRECT_NOT_SELECTED
                    ? Colors.CloneGreen
                    : feedback === FeedbackEnum.ERROR
                    ? Colors.DeleteRed
                    : '#ffffff'}
            3px dashed;
    `,

    Feedback: styled.p`
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 10px;
    `,

    Pallet: {
        Container: styled.div`
            display: flex;
            gap: 1rem;
            margin: 10px;
        `,
    },
};

const conicColors: ProgressProps['strokeColor'] = {
    '0%': '#d34710',
    '50%': '#ffe58f',
    '100%': '#0bd837',
};

interface IQuizInteractionPublic {
    quizzes: ComponentQuizModel[];
    componente: ComponentInteractionModel;
    openModal: (src: string) => void;
    setBlockConcluirConteudo: (value: boolean) => void;
}

const QuizInteractionPublic = ({ quizzes, openModal, componente, setBlockConcluirConteudo }: IQuizInteractionPublic) => {
    const [index, setIndex] = useState<number>(0);
    const [quizSelected, setQuizSelected] = useState<ComponentQuizModel>(new ComponentQuizModel());
    const [nextPergunta, setNextPergunta] = useState<boolean>(false);
    const [quizConcluido, setQuizConcluido] = useState<boolean>(false);

    // Variaveis para mostrar a métrica ao finalizar os quizzes
    const [totalAlternativasCorretas, setTotalAlternativasCorretas] = useState<number>(0);
    const [totalAlternativasAcertadas, setTotalAlternativasAcertadas] = useState<number>(0);

    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [alternativaUnicaSelecionada, setAlternativaUnicaSelecionada] = useState(-1);
    const [alternativaMultiplascaSelecionada, setAlternativaMultiplasSelecionada] = useState<number[]>([]);

    const handleOptionChange = (value: any) => {
        setAlternativaUnicaSelecionada(value);
    };

    const handleCalcAcertos = () => {
        let altCorretas = totalAlternativasCorretas;
        let altAcertadas = totalAlternativasAcertadas;

        quizSelected.alternativas?.map(alt => {
            if (alt.correct) {
                altCorretas += 1;

                if (quizSelected.multiplos) {
                    alternativaMultiplascaSelecionada.map(altSelected => {
                        if (altSelected === alt.id) {
                            altAcertadas += 1;
                        }
                    });
                } else {
                    if (alternativaUnicaSelecionada === alt.id) {
                        altAcertadas += 1;
                    }
                }
            }
        });

        setTotalAlternativasCorretas(altCorretas);
        setTotalAlternativasAcertadas(altAcertadas);
    };

    const calcularPorcentagem = () => {
        if (totalAlternativasCorretas === 0) return 0;
        return (totalAlternativasAcertadas / totalAlternativasCorretas) * 100;
    };

    const feedbackUniqAlt = (idAlt: number, isCorreact: boolean): FeedbackEnum => {
        if (!isSubmit) {
            return FeedbackEnum.NENHUM;
        }

        if (isCorreact) {
            if (idAlt === alternativaUnicaSelecionada) {
                return FeedbackEnum.CORRECT;
            } else if (idAlt !== alternativaUnicaSelecionada) {
                return FeedbackEnum.CORRECT;
            } else {
                return FeedbackEnum.CORRECT_NOT_SELECTED;
            }
        }

        if (!isCorreact) {
            if (idAlt === alternativaUnicaSelecionada) {
                return FeedbackEnum.ERROR;
            }
        }

        return FeedbackEnum.NENHUM;
    };

    const feedbackMultiAlt = (idAlt: number, isCorreact: boolean): FeedbackEnum => {
        if (!isSubmit) {
            return FeedbackEnum.NENHUM;
        }

        const isSelected = alternativaMultiplascaSelecionada.includes(idAlt);
        const isCorrect = isCorreact; // `isCorreact` indica se a alternativa é correta

        if (isSelected && isCorrect) {
            return FeedbackEnum.CORRECT; // Alternativa correta e selecionada
        }

        if (!isSelected && isCorrect) {
            return FeedbackEnum.CORRECT_NOT_SELECTED; // Alternativa correta e não selecionada
        }

        if (isSelected && !isCorrect) {
            return FeedbackEnum.ERROR; // Alternativa errada e selecionada
        }

        return FeedbackEnum.NENHUM; // Nenhuma condição se aplica
    };

    const handleCheckboxChange = (value: number, checked: boolean) => {
        setAlternativaMultiplasSelecionada(prevState => {
            if (checked) {
                // Adiciona o número à lista se o checkbox for marcado
                return [...prevState, value];
            } else {
                // Remove o número da lista se o checkbox for desmarcado
                return prevState.filter(item => item !== value);
            }
        });
    };

    const refazerQuizzes = () => {
        setQuizSelected(quizzes[0]);
        setAlternativaUnicaSelecionada(-1);
        setAlternativaMultiplasSelecionada([]);
        setTotalAlternativasAcertadas(0);
        setTotalAlternativasCorretas(0);
        setIsSubmit(false);
        setQuizConcluido(false);
        setIndex(0);
    };

    const goNextPergunta = () => {
        setIndex(index + 1);
        setNextPergunta(false);
        setQuizSelected(quizzes[index + 1]);
        setAlternativaUnicaSelecionada(-1);
        setAlternativaMultiplasSelecionada([]);
        setIsSubmit(false);
    };

    const submitResposta = () => {
        setIsSubmit(true);
        handleCalcAcertos();

        if (quizzes.length > 1) {
            if (index + 1 < quizzes.length) {
                return setNextPergunta(true);
            }
        }

        if (index + 1 === quizzes.length) {
            setQuizConcluido(true);

            return setBlockConcluirConteudo(false);
        }
    };

    useEffect(() => {
        setQuizSelected(quizzes[index]);
    }, [quizzes]);

    useEffect(() => {
        if (componente.obrigatory) {
            setBlockConcluirConteudo(true);
        }
    }, [componente]);

    return (
        <StyleQuiz.Container>
            <Styled.TextContent
                style={{ margin: 0 }}
                onClick={e => {
                    const target = e.target as HTMLImageElement;
                    if (target.tagName === 'IMG') {
                        openModal(target.src);
                    }
                }}
                onMouseOver={e => {
                    const target = e.target as HTMLImageElement;
                    if (target.tagName === 'IMG') {
                    }
                }}
                dangerouslySetInnerHTML={{
                    __html: quizSelected?.pergunta ?? '',
                }}
            />
            <Divider />
            {quizSelected &&
                quizSelected.alternativas &&
                quizSelected.alternativas.map((alt, index) => {
                    if (quizSelected.multiplos) {
                        return (
                            <StyleQuiz.Alternativa key={index} feedback={feedbackMultiAlt(alt.id, alt.correct)}>
                                <Checkbox
                                    key={index}
                                    checked={alternativaMultiplascaSelecionada.includes(alt.id)}
                                    disabled={isSubmit}
                                    onChange={e => handleCheckboxChange(alt.id, e.target.checked)}
                                >
                                    {alt.alternativa}
                                </Checkbox>
                            </StyleQuiz.Alternativa>
                        );
                    } else {
                        return (
                            <StyleQuiz.Alternativa key={index} feedback={feedbackUniqAlt(alt.id, alt.correct)}>
                                <Radio
                                    disabled={isSubmit}
                                    name={quizSelected.pergunta}
                                    value={alt.id}
                                    checked={alt.id === alternativaUnicaSelecionada}
                                    onChange={e => handleOptionChange(e.target.value)}
                                >
                                    {alt.alternativa}
                                </Radio>
                            </StyleQuiz.Alternativa>
                        );
                    }
                })}
            <Divider />
            {isSubmit && <StyleQuiz.Feedback dangerouslySetInnerHTML={{ __html: quizSelected.feedback }} />}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {!quizConcluido ? (
                    <>
                        <ConfigProvider
                            button={{
                                style: {
                                    backgroundColor: Colors.LightGreen,
                                    width: 200,
                                    fontWeight: 700,
                                },
                            }}
                        >
                            <Button
                                onClick={submitResposta}
                                className={(!quizSelected.multiplos && alternativaUnicaSelecionada === -1) || isSubmit ? 'disabled-button' : ''}
                                disabled={(!quizSelected.multiplos && alternativaUnicaSelecionada === -1) || isSubmit}
                                type="primary"
                            >
                                {`Responder`.toLocaleUpperCase()}
                            </Button>
                        </ConfigProvider>
                        {quizzes.length > 1 && (
                            <p style={{ fontStyle: 'italic' }}>
                                Pergunta: {index + 1} / {quizzes.length}
                            </p>
                        )}
                        {nextPergunta && (
                            <ConfigProvider
                                button={{
                                    style: {
                                        backgroundColor: Colors.Yellow2,
                                        width: 200,
                                        fontWeight: 700,
                                    },
                                }}
                            >
                                <Button onClick={goNextPergunta} type="primary">
                                    {`Próxima pergunta`.toLocaleUpperCase()}
                                </Button>
                            </ConfigProvider>
                        )}
                    </>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '100%' }}>
                        <ConfigProvider
                            button={{
                                style: {
                                    backgroundColor: Colors.Yellow2,
                                    width: 200,
                                    fontWeight: 700,
                                },
                            }}
                        >
                            <Button onClick={refazerQuizzes} type="primary">
                                {`Refazer o quiz`.toLocaleUpperCase()}
                            </Button>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Progress
                                    size={'small'}
                                    type="dashboard"
                                    percent={calcularPorcentagem()}
                                    strokeColor={conicColors}
                                    format={percent => (percent === 100 ? '100%' : `${parseInt(percent?.toString() ?? '0')}%`)}
                                />
                                <span>Porcentagem de acertos</span>
                            </div>
                        </ConfigProvider>
                    </div>
                )}
            </div>
        </StyleQuiz.Container>
    );
};

export default QuizInteractionPublic;
