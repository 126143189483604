import ComponentQuizPontuacaoGlobal from 'models/componentInteraction/ComponentQuizPontuacaoGlobal';
import { GenericService } from '../Generic.service';

export class ComponentQuizPontuacaoService extends GenericService {
    readonly URI: string = '/v1/componentQuizPontuacao';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public insertAtualization(model: ComponentQuizPontuacaoGlobal, codigoInteraction: number) {
        return this.post('' + codigoInteraction, model);
    }

    public deletarQuiz(idQuiz: number) {
        return this.delete(idQuiz);
    }

    public deletarAlternativaQuiz(idAlternativa: number) {
        return this.deleteByParams('alternativa/' + idAlternativa);
    }
    
    public deletarFeedbackQuiz(idFeedback: number) {
        return this.deleteByParams('feedback/' + idFeedback);
    }
}
