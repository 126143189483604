import { Button, Input, InputNumber, Select, Slider, SliderSingleProps, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { ComponentQuizPontuacaoService } from 'core/http/service/componetInteraction/ComponentQuizPontuacao.service';
import { ComponentReguaAvaliativaService } from 'core/http/service/componetInteraction/ComponentReguaAvaliativa.service';
import { ComponentReguaAvaliativaFeedbackService } from 'core/http/service/componetInteraction/ComponentReguaAvaliativaFeedback.service';
import { ComponentReguaAvaliativaQquestionsService } from 'core/http/service/componetInteraction/ComponentReguaAvaliativaQuestions.service';
import Colors from 'enums/Colors';
import ComponentReguaAvaliativaModel from 'models/componentInteraction/ComponenteReguaAvaliativa';
import ComponentQuizAlternativasModel from 'models/componentInteraction/ComponentQuizAlternativas';
import ComponentQuizPontuacao from 'models/componentInteraction/ComponentQuizPontuacao';
import ComponentQuizPontuacaoAlternativas from 'models/componentInteraction/ComponentQuizPontuacaoAlternativas';
import ComponentQuizPontuacaoFeedback from 'models/componentInteraction/ComponentQuizPontuacaoFeedback';
import ComponentQuizPontuacaoGlobal from 'models/componentInteraction/ComponentQuizPontuacaoGlobal';
import ComponentReguaAvaliativaFeedbacks from 'models/componentInteraction/ComponentReguaAvaliativaFeedbacks';
import ComponentReguaAvaliativaOptions from 'models/componentInteraction/ComponentReguaAvaliativaOptions';
import ComponentReguaAvaliativaQuestions from 'models/componentInteraction/ComponentReguaAvaliativaQuestions';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useEffect, useState } from 'react';
import { FaRegEye } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { MdDeleteForever, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdRemove, MdSaveAs } from 'react-icons/md';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        max-width: 1280px;
        gap: 1rem;
    `,

    Row: styled.div`
        display: flex;
    `,

    RowEnd: styled.div`
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        gap: 1rem;
    `,

    RowBetween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
    `,

    Preview: styled.div<{ isPreview: boolean }>`
        border: 1px solid ${Colors.Blue};
        width: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: ${({ isPreview }) => (isPreview ? Colors.Blue : Colors.White)};
        cursor: pointer;
    `,

    Column: styled.div`
        display: flex;
        width: 100%;
        gap: 0.5rem;
        flex-direction: column;
    `,

    Regua: {
        Container: styled.div``,
    },

    Options: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 52px;
        `,

        NumberPosition: styled.div`
            width: 50px;
            height: 50px;
            padding: 15px 21px 15px 21px;
            gap: 16px;
            border-radius: 5px;
            border: 1px 0px 0px 0px;
            opacity: 0px;
            border: 1px solid #e6e6e6;
            background: #f9f9f9;
        `,
    },

    Navigation: {
        Arrow: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
            width: 62px;
            border-radius: 5px;
            border: 1px solid #e6e6e6;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                border: 1px solid ${Colors.Blue};
            }
        `,

        ItemFooter: styled.div<{ isActive: boolean }>`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            border: 1px solid ${Colors.Blue};
            color: ${({ isActive }) => (isActive ? Colors.White : Colors.Blue)};
            background-color: ${({ isActive }) => (isActive ? Colors.Blue : Colors.White)};
            cursor: pointer;
        `,
    },

    Feedback: {
        Container: styled.div`
            display: flex;
            gap: 1rem;
            height: 133px;
            width: 100%;
        `,
    },

    Button: styled.button`
        width: 38px;
        height: 38px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${Colors.Grey11};
        margin-left: 10px;
    `,
};

interface IComponentReguaAvaliativaAdmin {
    dados: ComponentReguaAvaliativaModel | null;
    codigoInteraction: number;
}

interface IComponent {
    niveis: ComponentReguaAvaliativaOptions[];
}
interface INiveis {
    nivel: ComponentReguaAvaliativaOptions;
    removeOptionIndex: () => void;
    handleText: (value: string) => void;
    isEdit: boolean;
    codigoQuestions: number;
    lastOption: boolean;
}

interface IFeedback {
    dados: ComponentReguaAvaliativaFeedbacks;
    handleValue: (isText: boolean, isMinNivel: boolean, value: string | number) => void;
    removeIndex: () => void;
    length: number;
    isEdit: boolean;
    handleSaveFeed: () => void;
}

const Feedback = ({ dados, handleValue, removeIndex, length, isEdit, handleSaveFeed }: IFeedback) => {
    const [isModify, setIsModify] = useState<boolean>(false);

    return (
        <Styled.Feedback.Container>
            <InputNumber
                placeholder="N"
                style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                maxLength={1}
                value={dados.nivelMin}
                min={1}
                onChange={v => {
                    handleValue(false, true, v ?? -1);
                    setIsModify(true);
                }}
            />
            <InputNumber
                placeholder="N"
                style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                maxLength={1}
                value={dados.nivelMax}
                min={1}
                onChange={v => {
                    handleValue(false, false, v ?? -1);
                    setIsModify(true);
                }}
            />
            <TextArea
                size="large"
                style={{ marginBottom: 22 }}
                showCount
                maxLength={200}
                rows={3}
                value={dados.text}
                onChange={e => {
                    handleValue(true, false, e.target.value);
                    setIsModify(true);
                }}
                placeholder="Texto do feedback"
            />
            {isEdit && isModify && (
                <MdSaveAs
                    size={24}
                    color={Colors.Blue}
                    cursor={'pointer'}
                    onClick={() => {
                        handleSaveFeed();
                        setIsModify(false);
                    }}
                />
            )}
            {length > 1 && <MdDeleteForever size={24} color={Colors.DeleteRed} cursor={'pointer'} onClick={removeIndex} />}
        </Styled.Feedback.Container>
    );
};

const Options = ({ nivel, removeOptionIndex, handleText, isEdit, codigoQuestions, lastOption }: INiveis) => {
    const [modifyText, setModifyText] = useState<boolean>(false);

    //service
    const service = new ComponentReguaAvaliativaQquestionsService();

    const handleSaveQuestions = async () => {
        const { status } = await service.atualizarOption(nivel, codigoQuestions);

        if (status === 200) {
            ToastSuccess('Opção salva com sucesso!');
            setModifyText(false);
        }
    };

    return (
        <Styled.Options.Container>
            <Styled.Options.NumberPosition>{nivel.nivel}</Styled.Options.NumberPosition>
            <Input
                size="large"
                style={{ height: '100%', width: '1000px' }}
                placeholder="Escreva a descrição do item aqui"
                showCount
                maxLength={100}
                value={nivel.text}
                onChange={e => {
                    handleText(e.target.value);
                    isEdit && setModifyText(true);
                }}
            />
            {isEdit && modifyText && <MdSaveAs size={24} color={Colors.Blue} cursor={'pointer'} onClick={handleSaveQuestions} />}
            {lastOption && <MdDeleteForever size={24} color={Colors.DeleteRed} cursor={'pointer'} onClick={removeOptionIndex} />}
        </Styled.Options.Container>
    );
};

interface IComponentQuizPontucaoAdmin {
    dados: ComponentQuizPontuacaoGlobal | null;
    codigoInteraction: number;
}

const ComponentQuizPontucaoAdmin = ({ dados, codigoInteraction }: IComponentQuizPontucaoAdmin) => {
    const [preview, setPreview] = useState<boolean>(false);
    const [quizGlobal, setQuizGlobal] = useState<ComponentQuizPontuacaoGlobal | null>(dados);
    const [indexQuiz, setIndexQuiz] = useState<number>(0);
    const [quantityQuizzes, setQuantityQuizzes] = useState<number>(1);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    //loadings
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    //QuestionsNotSave
    const [numberIndexQuestionNotSave, setNumberIndexQuestionNotSave] = useState<number[]>([]);

    const [textQuestionAtt, setTextQuetionsAtt] = useState<boolean>(false);
    const [textRegua, setTextRegua] = useState<boolean>(false);

    //services
    const service = new ComponentQuizPontuacaoService();

    const nextRegua = () => {
        if (quantityQuizzes > 1 && indexQuiz + 1 < quantityQuizzes) {
            setIndexQuiz(indexQuiz + 1);
            return;
        }

        if (indexQuiz + 1 === quantityQuizzes) {
            setIndexQuiz(99);
        }
    };

    const reviver = () => {
        if (indexQuiz === 99) {
            setIndexQuiz(quantityQuizzes - 1);
            return;
        }

        if (indexQuiz > 0) {
            setIndexQuiz(indexQuiz - 1);
        }
    };

    const handleSave = async () => {
        if (quizGlobal) {
            setLoadingSave(true)
            const { data, status } = await service.insertAtualization(quizGlobal, codigoInteraction);

            if (status === 201) {
                ToastSuccess('Quiz salvo com sucesso!');
                setQuizGlobal(data);
                setIsEdit(true);
            }
            setLoadingSave(false)
        }
    };

    const handleNewAlternativa = (index: number) => {
        const novaAlternativa = new ComponentQuizPontuacaoAlternativas().fromJSON({
            id: -1,
            text: '',
            pontos: 1,
        });

        if (quizGlobal && quizGlobal.quizzes) {
            const quizzesAtualizados = quizGlobal.quizzes.map((q: ComponentQuizPontuacao, i: number) => {
                if (index === i) {
                    // Retorna um novo objeto quiz com as alternativas atualizadas
                    return new ComponentQuizPontuacao().fromJSON({
                        id: q.id,
                        title: q.title,
                        description: q.description,
                        multipleChoice: q.multipleChoice,
                        alternativas: [...q.alternativas, novaAlternativa], // Adiciona a nova alternativa
                    });
                }
                return q; // Retorna o quiz original se não for o que deve ser atualizado
            });

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: quizzesAtualizados, // Atualiza a propriedade quizzes
            }));
        }
    };
    const handleNewQuizze = () => {
        const novaQuizze = new ComponentQuizPontuacao().fromJSON({
            id: -1,
            title: '',
            description: '',
            multipleChoice: true,
            alternativas: [
                new ComponentQuizPontuacaoAlternativas().fromJSON({
                    id: -1,
                    text: '',
                    pontos: 1,
                }),
            ],
        });

        if (quizGlobal && quizGlobal.quizzes) {
            const quizzes = [...quizGlobal.quizzes, novaQuizze];

            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: quizzes, // Atualiza a propriedade quizzes
            }));

            setQuantityQuizzes(quantityQuizzes + 1);
            setIndexQuiz(indexQuiz + 1);
        }
    };

    const removeAlternativa = async (index: number, indexAlt: number) => {
        const quizIndex = index; // índice do quiz que você deseja atualizar
        const alternativaIndex = indexAlt; // índice da alternativa que você deseja remover

        let idAlternativaRemove = -1;

        // Verifica se quizGlobal e quizzes existem
        if (quizGlobal && quizGlobal.quizzes) {
            const quizzesAtualizados = quizGlobal.quizzes.map((q: ComponentQuizPontuacao, i: number) => {
                if (quizIndex === i) {
                    idAlternativaRemove = q.alternativas[indexAlt].id;

                    // Retorna um novo objeto quiz com as alternativas atualizadas
                    const alternativasAtualizadas = q.alternativas.filter((_, altIndex: number) => altIndex !== alternativaIndex);
                    return new ComponentQuizPontuacao().fromJSON({
                        id: q.id,
                        title: q.title,
                        description: q.description,
                        multipleChoice: q.multipleChoice,
                        alternativas: alternativasAtualizadas, // Remove a alternativa pelo índice
                    });
                }
                return q; // Retorna o quiz original se não for o que deve ser atualizado
            });

            if (idAlternativaRemove != -1) {
                await service.deletarAlternativaQuiz(idAlternativaRemove);
            }

            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: quizzesAtualizados, // Atualiza a propriedade quizzes
            }));
        }
    };

    const atualizationTextQuizze = (index: number, isTitle: boolean, value: string) => {
        if (quizGlobal && quizGlobal.quizzes) {
            const quizzesAtualizados = quizGlobal.quizzes.map((q: ComponentQuizPontuacao, i: number) => {
                if (index === i) {
                    // Retorna um novo objeto quiz com as alternativas atualizadas
                    return new ComponentQuizPontuacao().fromJSON({
                        id: q.id,
                        title: isTitle ? value : q.title,
                        description: !isTitle ? value : q.description,
                        multipleChoice: q.multipleChoice,
                        alternativas: q.alternativas, // Adiciona a nova alternativa
                    });
                }
                return q; // Retorna o quiz original se não for o que deve ser atualizado
            });

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: quizzesAtualizados, // Atualiza a propriedade quizzes
            }));
        }
    };
    const handleMultipleChoice = (index: number, checked: boolean) => {
        if (quizGlobal && quizGlobal.quizzes) {
            const quizzesAtualizados = quizGlobal.quizzes.map((q: ComponentQuizPontuacao, i: number) => {
                if (index === i) {
                    // Retorna um novo objeto quiz com as alternativas atualizadas
                    return new ComponentQuizPontuacao().fromJSON({
                        id: q.id,
                        title: q.title,
                        description: q.description,
                        multipleChoice: checked,
                        alternativas: q.alternativas, // Adiciona a nova alternativa
                    });
                }
                return q; // Retorna o quiz original se não for o que deve ser atualizado
            });

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: quizzesAtualizados, // Atualiza a propriedade quizzes
            }));
        }
    };

    const removeQuizze = async () => {
        if (quizGlobal && quizGlobal.quizzes) {
            const idQuizze = quizGlobal.quizzes[indexQuiz].id;

            if (idQuizze && idQuizze !== -1) {
                const { status } = await service.deletarQuiz(idQuizze);

                if (status === 204) {
                    ToastSuccess('Quiz removido com sucesso!');
                }
            }

            // Filtra a lista de quizzes para remover o quiz no indexQuiz
            const listAtt = quizGlobal.quizzes.filter((_, i) => indexQuiz !== i);

            // Atualiza o índice, se necessário
            const newIndexQuiz = indexQuiz > 0 ? indexQuiz - 1 : 0; // Garante que não vá para um índice negativo
            setIndexQuiz(newIndexQuiz);
            setQuantityQuizzes(quantityQuizzes - 1);

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: listAtt,
            }));
        }
    };

    const removeFeedback = async (index: number) => {
        if (quizGlobal && quizGlobal.feedbacks) {
            const idFeedback = quizGlobal.feedbacks[index].id;

            if (idFeedback && idFeedback !== -1) {
                await service.deletarFeedbackQuiz(idFeedback);
            }
            // Filtra a lista de quizzes para remover o quiz no indexQuiz
            const listAtt = quizGlobal.feedbacks.filter((_, i) => index !== i);

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                feedbacks: listAtt,
            }));
        }
    };

    const atualizationAlternativa = (indexQuiz: number, indexAlt: number, isPontos: boolean, value: string) => {
        if (quizGlobal && quizGlobal.quizzes) {
            const quizzesAtualizados = quizGlobal.quizzes.map((q: ComponentQuizPontuacao, i: number) => {
                if (indexQuiz === i) {
                    // Acessa a alternativa diretamente
                    const alternativa = q.alternativas[indexAlt];

                    // Cria uma nova alternativa com os valores atualizados
                    const alternativaAtualizada = new ComponentQuizPontuacaoAlternativas().fromJSON({
                        id: alternativa.id,
                        text: !isPontos ? value : alternativa.text,
                        pontos: isPontos ? parseInt(value) : alternativa.pontos,
                    });

                    // Retorna um novo objeto quiz com a alternativa atualizada
                    return new ComponentQuizPontuacao().fromJSON({
                        id: q.id,
                        title: q.title,
                        description: q.description,
                        multipleChoice: q.multipleChoice,
                        alternativas: q.alternativas.map((a: ComponentQuizPontuacaoAlternativas, altIndex: number) =>
                            altIndex === indexAlt ? alternativaAtualizada : a
                        ),
                    });
                }
                return q; // Retorna o quiz original se não for o que deve ser atualizado
            });

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                quizzes: quizzesAtualizados, // Atualiza a propriedade quizzes
            }));
        }
    };

    const atualizationFeedback = (indexFeedback: number, isMinValue: boolean, isText: boolean, value: string) => {
        if (quizGlobal && quizGlobal.feedbacks) {
            const feedbackAtualizados = quizGlobal.feedbacks.map((f: ComponentQuizPontuacaoFeedback, i: number) => {
                if (i === indexFeedback) {
                    return new ComponentQuizPontuacaoFeedback().fromJSON({
                        id: f.id,
                        nivelMin: isMinValue && !isText ? parseInt(value) : f.nivelMin,
                        nivelMax: !isMinValue && !isText ? parseInt(value) : f.nivelMax,
                        text: !isMinValue && isText ? value : f.text,
                    });
                }

                return f;
            });

            // Atualiza o estado com a nova lista de quizzes
            setQuizGlobal((prev: any) => ({
                ...prev,
                feedbacks: feedbackAtualizados, // Atualiza a propriedade quizzes
            }));
        }
    };

    const addNewFeedback = () => {
        let lastFeed = quizGlobal?.feedbacks.at(-1)?.nivelMax ?? 1;

        const novoFeedback = new ComponentReguaAvaliativaFeedbacks().fromJSON({
            id: -1,
            nivelMin: lastFeed + 1,
            nivelMax: lastFeed + 2,
            text: '',
        });

        if (quizGlobal && quizGlobal.feedbacks) {
            const feedbacks = [...quizGlobal.feedbacks, novoFeedback];

            setQuizGlobal((prev: any) => ({
                ...prev,
                feedbacks: feedbacks, // Atualiza a propriedade quizzes
            }));
        }
    };

    const handleTitle = (index: number, value: string) => {
        atualizationTextQuizze(index, true, value);
    };

    const handleDescription = (index: number, value: string) => {
        atualizationTextQuizze(index, false, value);
    };

    useEffect(() => {
        if (Array.isArray(dados?.quizzes) && dados?.quizzes.length === 0) {
            let global = new ComponentQuizPontuacaoGlobal().fromJSON({
                quizzes: [
                    new ComponentQuizPontuacao().fromJSON({
                        id: -1,
                        title: '',
                        description: '',
                        multipleChoice: true,
                        alternativas: [
                            new ComponentQuizPontuacaoAlternativas().fromJSON({
                                id: -1,
                                text: '',
                                pontos: 1,
                            }),
                        ],
                    }),
                ],
                feedbacks: [
                    new ComponentQuizPontuacaoFeedback().fromJSON({
                        id: -1,
                        nivelMin: 1,
                        nivelMax: 2,
                        text: '',
                    }),
                ],
            });
            setQuizGlobal(global);
            setQuantityQuizzes(1);
        } else {
            if (dados) {
                setQuantityQuizzes(dados.quizzes.length);
                setQuizGlobal(dados);
                setIsEdit(true);
            }
        }
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.RowBetween>
                <Styled.Title>Quiz com pontuação</Styled.Title>
            </Styled.RowBetween>
            {quizGlobal?.quizzes.map((q: ComponentQuizPontuacao, indexQuizMap: number) => {
                if (indexQuizMap === indexQuiz && indexQuiz != 99) {
                    const reviverButton: boolean = indexQuizMap > 0;

                    return (
                        <>
                            <Input
                                size="large"
                                placeholder="Digite o título"
                                showCount
                                maxLength={200}
                                value={q.title}
                                onChange={e => handleTitle(indexQuizMap, e.target.value)}
                            />
                            <ReactQuill
                                placeholder="Descrição do quiz"
                                style={{ marginBottom: '20px' }}
                                theme="snow"
                                modules={editorConfig.modules2}
                                formats={editorConfig.formats2}
                                value={q.description}
                                onChange={val => handleDescription(indexQuiz, val)}
                            />
                            <Styled.Row>
                                {reviverButton && (
                                    <Styled.Navigation.Arrow onClick={reviver}>
                                        <MdKeyboardArrowLeft size={22} />
                                    </Styled.Navigation.Arrow>
                                )}
                                <Styled.Column>
                                    <Styled.Title>Alternativas</Styled.Title>
                                    {q.alternativas.map((a: ComponentQuizPontuacaoAlternativas, indexAlt: number) => (
                                        <Styled.Row style={{ gap: '0.5rem' }} key={indexAlt}>
                                            <InputNumber
                                                placeholder="N"
                                                style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                                                value={a.pontos}
                                                min={1}
                                                onChange={v => atualizationAlternativa(indexQuizMap, indexAlt, true, v?.toString() ?? '')}
                                            />
                                            <Input
                                                size="large"
                                                placeholder="Texto da alternativa"
                                                showCount
                                                maxLength={200}
                                                value={a.text}
                                                onChange={e => atualizationAlternativa(indexQuizMap, indexAlt, false, e.target.value)}
                                            />
                                            {q.alternativas.length > 1 && (
                                                <Styled.Button onClick={() => removeAlternativa(indexQuiz, indexAlt)}>
                                                    <MdDeleteForever size={32} color={Colors.DeleteRed} />
                                                </Styled.Button>
                                            )}
                                        </Styled.Row>
                                    ))}
                                </Styled.Column>
                                <Styled.Navigation.Arrow onClick={nextRegua}>
                                    <MdKeyboardArrowRight size={22} />
                                </Styled.Navigation.Arrow>
                            </Styled.Row>
                            <Styled.RowBetween>
                                <Styled.Column style={{ width: 'auto' }}>
                                    <Styled.SubTitle>Adicionar mais uma alternativa</Styled.SubTitle>
                                    <Button size="large" style={{ width: 200 }} onClick={() => handleNewAlternativa(indexQuizMap)}>
                                        Adicionar + 1
                                    </Button>
                                </Styled.Column>

                                {isEdit && (
                                    <>
                                        <Styled.Column style={{ width: 'auto' }}>
                                            <Styled.SubTitle>Salvar Quiz</Styled.SubTitle>
                                            <Button loading={loadingSave} size="large" style={{ width: 200 }} onClick={handleSave}>
                                                <MdSaveAs /> Salvar
                                            </Button>
                                        </Styled.Column>
                                        {indexQuiz != 0 && (
                                            <Styled.Column style={{ width: 'auto' }}>
                                                <Styled.SubTitle>Remover Quiz</Styled.SubTitle>
                                                <Button size="large" style={{ width: 200, color: Colors.DeleteRed }} onClick={removeQuizze}>
                                                    <MdDeleteForever /> Remover
                                                </Button>
                                            </Styled.Column>
                                        )}
                                    </>
                                )}
                                <Styled.Column style={{ width: 'auto' }}>
                                    <Styled.SubTitle>Tipo</Styled.SubTitle>
                                    <Switch
                                        checkedChildren="múltipla escolha"
                                        unCheckedChildren="única escolha"
                                        value={q.multipleChoice}
                                        onChange={c => handleMultipleChoice(indexQuizMap, c)}
                                    />
                                </Styled.Column>
                                <Styled.Column style={{ width: 'auto' }}>
                                    <Styled.SubTitle>Adicionar mais um quiz</Styled.SubTitle>
                                    <Button size="large" style={{ width: 200 }} onClick={() => handleNewQuizze()}>
                                        Adicionar + 1
                                    </Button>
                                </Styled.Column>
                            </Styled.RowBetween>
                        </>
                    );
                }
            })}

            {indexQuiz === 99 && (
                <>
                    <Styled.Row>
                        <Styled.Navigation.Arrow onClick={reviver}>
                            <MdKeyboardArrowLeft size={22} />
                        </Styled.Navigation.Arrow>
                        <Styled.Column>
                            <Styled.Title>Feedbacks</Styled.Title>
                            {quizGlobal?.feedbacks.map((f: ComponentQuizPontuacaoFeedback, index: number) => (
                                <Styled.Row style={{ gap: '0.5rem' }} key={index}>
                                    <InputNumber
                                        placeholder="N"
                                        style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                                        value={f.nivelMin}
                                        min={1}
                                        onChange={v => atualizationFeedback(index, true, false, v?.toString() ?? '')}
                                    />
                                    <InputNumber
                                        placeholder="N"
                                        style={{ height: 62, width: 82, textAlign: 'center', fontSize: 32 }}
                                        value={f.nivelMax}
                                        min={1}
                                        onChange={v => atualizationFeedback(index, false, false, v?.toString() ?? '')}
                                    />
                                    <Input
                                        size="large"
                                        placeholder="Texto do feedback"
                                        value={f.text}
                                        onChange={e => atualizationFeedback(index, false, true, e.target.value)}
                                    />
                                    {quizGlobal.feedbacks.length > 0 && (
                                        <Styled.Button onClick={() => removeFeedback(index)}>
                                            <MdDeleteForever size={32} color={Colors.DeleteRed} />
                                        </Styled.Button>
                                    )}
                                </Styled.Row>
                            ))}

                            <Styled.Column style={{ width: 'auto' }}>
                                <Styled.SubTitle>Adicionar mais um feedback</Styled.SubTitle>
                                <Styled.RowBetween>
                                    <Button size="large" style={{ width: 200 }} onClick={() => addNewFeedback()}>
                                        Adicionar + 1
                                    </Button>

                                    <Button loading={loadingSave} size="large" style={{ width: 200 }} onClick={handleSave}>
                                        <MdSaveAs /> Salvar
                                    </Button>
                                </Styled.RowBetween>
                            </Styled.Column>
                        </Styled.Column>
                    </Styled.Row>
                </>
            )}

            <Styled.RowEnd>
                <p>Navegue entre os quiz:</p>
                {Array.from({ length: quantityQuizzes }).map((_, index) => (
                    <Styled.Navigation.ItemFooter onClick={() => setIndexQuiz(index)} isActive={indexQuiz == index} key={index}>
                        {index + 1}
                    </Styled.Navigation.ItemFooter>
                ))}

                <Styled.Navigation.ItemFooter onClick={() => setIndexQuiz(99)} isActive={indexQuiz === 99}>
                    <FiCheckCircle color={indexQuiz === 99 ? Colors.White : Colors.Blue} />
                </Styled.Navigation.ItemFooter>
            </Styled.RowEnd>
        </Styled.Container>
    );
};

export default ComponentQuizPontucaoAdmin;
