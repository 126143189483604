import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import ComponentSanfonaModel from './ComponentSanfona';
import ComponentQuizModel from './ComponentQuiz';
import CorrespondentModel from './CorrespondentModel';
import ComponentCarrosselInteraction from './ComponentCarrosselInterarion';
import ComponentAbasModel from './ComponentAbas';
import ComponentBaralhoModel from './ComponentBaralho';
import ComponentReguaAvaliativaModel from './ComponenteReguaAvaliativa';
import ComponentTimeLine from './ComponentTimeLine';
import ComponentQuizPontuacao from './ComponentQuizPontuacao';
import ComponentQuizPontuacaoGlobal from './ComponentQuizPontuacaoGlobal';
import { ComponentCenarioGlobal } from './ComponentCenarioGlobal';

interface ComponentInteractionJson {
    id: number;
    tipo: ComponentInteractionEnum;
    conteudo: string;
    cor: string;
    quantityVisible: number;
    ordem: number;
    obrigatory: boolean;
    sanfona: ComponentSanfonaModel[] | null;
    quiz: ComponentQuizModel[] | null;
    correspondent: CorrespondentModel | null;
    carrossel: ComponentCarrosselInteraction | null;
    abas: ComponentAbasModel[] | null;
    baralho: ComponentBaralhoModel[] | null;
    reguaAvaliativa: ComponentReguaAvaliativaModel | null;
    timelines: ComponentTimeLine[] | null;
    quizPontuacao: ComponentQuizPontuacaoGlobal | null;
    cenarios: ComponentCenarioGlobal | null;
}

class ComponentInteractionModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    tipo: ComponentInteractionEnum = ComponentInteractionEnum.NENHUM;

    @jsonProperty(String)
    conteudo: string = '';

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(Number)
    quantityVisible: number = -1;

    @jsonProperty(String, null)
    cor: string | null = null;

    @jsonProperty(Boolean, null)
    obrigatory: boolean | null = null;

    sanfona: ComponentSanfonaModel[] | null = null;

    quiz: ComponentQuizModel[] | null = null;

    correspondent: CorrespondentModel | null = null;

    abas: ComponentAbasModel[] | null = null;

    carrossel: ComponentCarrosselInteraction | null = null;

    baralho: ComponentBaralhoModel[] | null = null;

    reguaAvaliativa: ComponentReguaAvaliativaModel | null = null;

    timelines: ComponentTimeLine[] | null = null;

    quizPontuacao: ComponentQuizPontuacaoGlobal | null = null;

    cenarios: ComponentCenarioGlobal | null = null;

    fromJSON(json: ComponentInteractionJson, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.tipo = json.tipo;
        this.conteudo = json.conteudo;
        this.cor = json.cor ?? null;
        this.quantityVisible = json.quantityVisible;
        this.ordem = json.ordem;
        this.obrigatory = json.obrigatory ?? null;
        this.sanfona = json.sanfona ?? null;
        this.quiz = json.quiz ?? null;
        this.correspondent = json.correspondent ?? null;
        this.carrossel = json.carrossel ?? null;
        this.abas = json.abas ?? null;
        this.baralho = json.baralho ?? null;
        this.reguaAvaliativa = json.reguaAvaliativa ?? null;
        this.timelines = json.timelines ?? null;
        this.quizPontuacao = json.quizPontuacao ?? null;
        this.cenarios = json.cenarios ?? null;
        return this;
    }
}

export default ComponentInteractionModel;