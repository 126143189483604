import React, { useCallback, useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import Tile from './Tile/Tile';
import bgTile1 from '../../../../images/painelSocio/bg-tile-1.webp';
import bgTile2 from '../../../../images/painelSocio/bg-tile-2.webp';
import bgTile3 from '../../../../images/painelSocio/bg-tile-3.webp';
import bgTile4 from '../../../../images/painelSocio/bg-tile-4.webp';
import { useHistory } from 'react-router-dom';
import { MobileMaxWidth } from 'constants/Dimensions';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { lmsIntegrationUrlSocioemocional, lmsIntegrationUrl, lmsIntegrationUrlSocioemocionalID } from 'constants/EnvironmentData';
import { IntegracaoInstrumentoService } from 'core/http/service/IntegracaoInstrumento.service';
import InstrumentoDTO from 'models/instrumento/InstrumentoDTO';
import StatusInstrumentoEnum from 'models/instrumento/StatusInstrumentoEnum';
import PDP from 'models/pdp/PDP';
import useFetch from 'hooks/useFetch';
import { useDispatch } from 'react-redux';
import * as pdpActions from '../../../../store/actions/Pdp';
import * as authenticationActions from '../../../../store/actions/Authentication';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import SocioEmocionalConteudo from 'models/SocioEmocionalConteudo';
import AdminSocioemocionalService from 'core/http/service/admin/AdminSocioemocional.service';
import { cloneDeep } from 'lodash';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { GTM_Page_Home, GTM_Page_Jornada_Desenvolvimento } from 'components/GoogleTagManager';
import { MdTrendingUp } from 'react-icons/md';
import { FiBookOpen } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';
import Colors from 'enums/Colors';
import { PrimaryButton } from 'components/UI/Buttons';
import { IntegracaoLmsService } from 'core/http/service/IntegracaoLMS.service';
import { MatriculaLMSEnum } from 'enums/MatriculaLMSEnum';

export interface ITileGrid {}

const service = new AdminSocioemocionalService();

const TileGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 40px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        gap: 15px;
        margin: 7px;

        display: flex;
        flex-direction: column;
    }
`;

export const Separator = styled.div(
    new FullCss({
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        marginRight: '1rem',
        borderBottom: '1px solid #cbd2e0',
        width: '90%',
    }).getCss()
);

export const TextVeja = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: ${Colors.Blue};
`;

export const ButtonGoToHumane = styled(PrimaryButton)`
    margin-bottom: 40px;
    height: 50px;
    margin-top: 40px;
`;

const ID_CURSO_TRILHA_FORMATIVA = lmsIntegrationUrlSocioemocionalID();

const TileGrid = ({}: ITileGrid) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const pdp: PDP | null = useAppSelector(state => state.pdpReducer.pdp);
    const dispatch = useDispatch();

    const [showPopupFinalizarInstrumento, setShowPopupFinalizarInstrumento] = useState<boolean>(false);
    const [showPopupRealizarInstrumento, setShowPopupRealizarInstrumento] = useState<boolean>(false);

    const [secondsLeft, setSecondsLeft] = useState(0);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [loadingButtonTrilha, setLoadingButtonTrilha] = useState<boolean>(false);

    const [hasNoInstrument, setHasNoInstrument] = useState<boolean>(false);
    const [hasNoFinisedInstrument, setHasNoFinishedIntrument] = useState<boolean>(false);
    const [integracaoBnccConcluida, setIntegracaoBnccConcluida] = useState<boolean>(false);
    const integracaoInstrumentoService = new IntegracaoInstrumentoService();
    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const [content, setContents] = useState<SocioEmocionalConteudo[]>([]);

    const serviceIntegracaoLms = new IntegracaoLmsService();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchContent = async () => {
        const { data } = await service.buscarSecoes();
        const _content = data.map((c: any) => new SocioEmocionalConteudo().fromJSON(c));
        setContents(_content);
        fetchImg(_content);
    };

    const fetchImg = async (_content: SocioEmocionalConteudo[]) => {
        const clone = cloneDeep(_content);

        for (let i = 0; i < clone.length; i++) {
            try {
                const { data, status } = await service.buscarAnexoSecao(clone[i].id);
                if (status == 200) clone[i].cover = 'data:image/*;base64,' + data.base64Content;
            } catch (e: any) {
                console.log(e);
            }
        }

        setContents(clone);
    };

    const getImage = (secId: string) => {
        const sec = getSec(secId);

        if (sec && sec.cover && sec.cover.startsWith('data:image/*;base64,')) return sec.cover;
        else {
            if (secId == 'AUTOAVALIACAO') return bgTile1;
            if (secId == 'DESENVOLVIMENTO') return bgTile2;
            if (secId == 'DIARIO') return bgTile3;
            if (secId == 'JORNADA') return bgTile4;
        }
    };

    const getButtonText = (secId: string): string => {
        if (secId == 'AUTOAVALIACAO') return isMobile ? 'Acessar' : 'Iniciar auto avaliação';
        if (secId == 'DESENVOLVIMENTO') return isMobile ? 'Acessar' : 'Acessar meu PDP';
        if (secId == 'DIARIO') return isMobile ? 'Acessar' : 'Acessar diário de bordo';
        if (secId == 'JORNADA')
            return isMobile
                ? 'Acessar'
                : loadingValidation
                ? 'Validando integração ...'
                : loadingButtonTrilha
                ? `Aguarde: ${secondsLeft === 60 ? '01:00' : '00:' + secondsLeft + ' segundos'} `
                : 'Acessar trilha formativa';
        return '';
    };

    // const timerAcesso = () => {
    //     setLoadingButtonTrilha(true);
    //     setSecondsLeft(60);

    //     const interval = setInterval(() => {
    //         setSecondsLeft(prev => {
    //             if (prev <= 1) {
    //                 // Quando o tempo acabar, reabilita o botão e limpa o intervalo
    //                 verificarD2l();
    //                 clearInterval(interval);
    //                 return 0;
    //             }

    //             return prev - 1;
    //         });
    //     }, 1000);
    // };

    // const verificarStatusMatricula = async (): Promise<boolean> => {
    //     const responseD2l = await serviceIntegracaoLms.statusMatricula(userProfile?.username ?? '', parseInt(ID_CURSO_TRILHA_FORMATIVA));

    //     const status = responseD2l.data === MatriculaLMSEnum.NaoMatriculado;

    //     return status;
    // };

    useFetch(async () => {
        if (secondsLeft === 30) {
            // const status = await verificarStatusMatricula();

            // if (!status) {
                setSecondsLeft(0);
                setLoadingButtonTrilha(false);
            // }
        }
    }, [secondsLeft]);

    // const verificarD2l = async () => {
    //     setLoadingValidation(true);
    //     try {
    //         if (await verificarStatusMatricula()) {
    //             const matricular = await serviceIntegracaoLms.matricular(userProfile?.username ?? '', parseInt(ID_CURSO_TRILHA_FORMATIVA));

    //             if (matricular.status === 200) {
    //                 setLoadingValidation(false);
    //                 timerAcesso();
    //             }
    //         } else {
    //             setLoadingButtonTrilha(false);
    //             setLoadingValidation(false);
    //         }
    //     } catch (error) {
    //         setLoadingValidation(true);
    //     }
    // };

    useFetch(
        async () => {
            try {
                await dispatch(pdpActions.consultarUltimoPdpPorPerfil(userProfile?.codigo ?? -1));
                const response = await integracaoInstrumentoService.consultarInstrumentos(
                    userProfile?.codigo ?? -1,
                    userProfile?.username ?? ''
                );

                const instrumentoArray: InstrumentoDTO[] = response.data.map((element: any) => new InstrumentoDTO().fromJSON(element));

                if (!instrumentoArray.length) {
                    setHasNoInstrument(true);
                }
                if (!instrumentoArray.find(item => item.situation === StatusInstrumentoEnum.FINISHED)) {
                    setHasNoFinishedIntrument(true);
                }
                
                setIntegracaoBnccConcluida(true);
            } catch (err: any) {
                console.log('Painel Socioemocional - Integração com o bncc não concluída');
                setHasNoFinishedIntrument(true);
                setHasNoInstrument(true);
            }
        },
        [],
        setLoading
    );

    useEffect(() => {
        fetchContent();
    }, [integracaoBnccConcluida]);

    const gotoPDP = async (isMobile: boolean) => {
        if (isMobile && hasNoInstrument) {
            setShowPopupRealizarInstrumento(true);
        } else if (isMobile && hasNoFinisedInstrument) {
            setShowPopupFinalizarInstrumento(true);
        } else {
            GTM_Page_Jornada_Desenvolvimento('cards_jornada', 'acessar_pdp_btn');
            await redirectToPdp();
        }
    };

    const gotoDiarioBordo = (isMobile: boolean) => {
        GTM_Page_Jornada_Desenvolvimento('cards_jornada', 'acessar_diario_bordo_btn');
        history.push('/painelSocioemocional/diarioBordo');
    };

    const gotoJornada = async (isMobile: boolean) => {
        GTM_Page_Jornada_Desenvolvimento('cards_jornada', 'acessar_trilha_formativa_btn');

        if (isMobile && hasNoInstrument) {
            setShowPopupRealizarInstrumento(true);
        } else if (isMobile && hasNoFinisedInstrument) {
            setShowPopupFinalizarInstrumento(true);
        } else {
            await redirectToJornada();
        }
    };

    const gotoAutoavaliacao = async (isMobile: boolean) => {
        if (integracaoBnccConcluida) {
            await dispatch(authenticationActions.getUsuarioLogado());
            GTM_Page_Jornada_Desenvolvimento('cards_jornada', 'acessar_auto_avaliacao_btn');
            history.push('/painelSocioemocional/autoavaliacao');
        } else {
            setOpenPopup(true);
        }
    };

    const getSec = (section: string): SocioEmocionalConteudo | null => {
        const sec = content.find(c => c.section == section);

        if (sec) return sec;
        else return null;
    };

    const redirectToJornada = async () => {
        history.push("/recursos/jornada-socioemocional-professores")
    };

    const redirectToPdp = useCallback(async () => {
        history.push('/painelSocioemocional/pdp');
    }, [pdp]);

    const closePopup = () => {
        setOpenPopup(false);
        setShowPopupFinalizarInstrumento(false);
        setShowPopupRealizarInstrumento(false);
    };

    const contentDefault = (value: any) => {
        switch (value) {
            case getSec('AUTOAVALIACAO')?.content:
                return `Todo desenvolvimento começa com uma avaliação sobre pontos fortes e os que precisam de atenção. Responda ao instrumento autoavaliativo de 
                competências socioemocionais docentes e aproveite para refletir sobre si mesmo e sua prática.`;

            case getSec('JORNADA')?.content:
                return `Faça um processo de aprendizado e desenvolvimento direcionado para cada macrocompetência relevante para o fazer docente.`;

            case getSec('DESENVOLVIMENTO')?.content:
                return `Faça seu Plano de Desenvolvimento Pessoal (PDP) e crie ou aperfeiçoe um percurso formativo único e desenvolvido por você especialmente para suas necessidades e segundo seus próprios objetivos, de carreira e de vida!`;

            case getSec('DIARIO')?.content:
                return `Crie um repositório de ideias, anotações e impressões em um único ambiente e tenha sempre em mãos esse espaço de registro do seu processo de reflexão e de busca ativa pelo seu desenvolvimento!`;
        }
    };

    const verifyContent = (value: any | null) => {
        if (value === null) return contentDefault(value);

        if (!isMobile) return value;

        switch (value) {
            case getSec('AUTOAVALIACAO')?.content:
                return 'Responda ao instrumento autoavaliativo de competências socioemocionais docentes e aproveite para refletir sobre si mesmo e sua prática.';

            case getSec('JORNADA')?.content:
                return `Faça um processo de aprendizado e desenvolvimento direcionado para cada macrocompetência relevante para o fazer docente.`;

            case getSec('DESENVOLVIMENTO')?.content:
                return null;

            case getSec('DIARIO')?.content:
                return null;
        }
    };

    const goToHome = () => {
        GTM_Page_Home('painel_socioemocional_home_mobile', 'callback_home_humane');
        history.push('/home');
    };

    return (
        <>
            {!loading && (
                <>
                    <TileGridContainer>
                        <>
                            <PopupQuestion
                                onButton={closePopup}
                                okButtonText={'Ok'}
                                cancelButtonText={'Ok'}
                                infoOnly={true}
                                question={'Por favor, atualize a página em alguns instantes e tente novamente. '}
                                visible={openPopup}
                            />

                            <Tile
                                index={1}
                                title="Autoavaliação"
                                undertitle={getSec('AUTOAVALIACAO')?.title ?? 'Seu ponto de partida'}
                                contentText={verifyContent(getSec('AUTOAVALIACAO')?.content)}
                                buttonText={getButtonText('AUTOAVALIACAO')}
                                buttonCallback={gotoAutoavaliacao}
                                srcBackground={getImage('AUTOAVALIACAO')}
                            />

                            <Tile
                                index={2}
                                title="Trilha formativa"
                                undertitle={getSec('JORNADA')?.title ?? 'Conhecimento e formação'}
                                contentText={verifyContent(getSec('JORNADA')?.content)}
                                buttonText={getButtonText('JORNADA')}
                                buttonCallback={gotoJornada}
                                srcBackground={getImage('JORNADA')}
                                disabled={hasNoFinisedInstrument || loadingValidation || loadingButtonTrilha}
                            />

                            {isMobile && (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Separator />
                                    <TextVeja>Veja Também</TextVeja>
                                </div>
                            )}

                            <Tile
                                index={3}
                                title="Plano de Desenvolvimento Pessoal"
                                undertitle={getSec('DESENVOLVIMENTO')?.title ?? 'Construa após a autoavaliação'}
                                contentText={verifyContent(getSec('DESENVOLVIMENTO')?.content)}
                                buttonText={getButtonText('DESENVOLVIMENTO')}
                                buttonCallback={gotoPDP}
                                srcBackground={getImage('DESENVOLVIMENTO')}
                                disabled={hasNoFinisedInstrument}
                                icon={<MdTrendingUp size={30} color={Colors.Blue} />}
                            />

                            <Tile
                                index={4}
                                title="Diário de Bordo"
                                undertitle={getSec('DIARIO')?.title ?? 'Registre a qualquer momento'}
                                contentText={verifyContent(getSec('DIARIO')?.content)}
                                buttonText={getButtonText('DIARIO')}
                                buttonCallback={gotoDiarioBordo}
                                srcBackground={getImage('DIARIO')}
                                icon={<FiBookOpen size={30} color={Colors.Blue} />}
                            />

                            {isMobile && (
                                <ButtonGoToHumane onClick={goToHome}>
                                    <IoIosArrowBack size={30} /> Voltar para a página Humane
                                </ButtonGoToHumane>
                            )}

                            <PopupQuestion
                                onButton={closePopup}
                                okButtonText={'Ok'}
                                cancelButtonText={'Ok'}
                                infoOnly={true}
                                question={'Você deve começar seu percurso pelo instrumento de avaliação!'}
                                visible={showPopupRealizarInstrumento}
                            />
                            <PopupQuestion
                                onButton={closePopup}
                                okButtonText={'Ok'}
                                cancelButtonText={'Ok'}
                                infoOnly={true}
                                question={'Você deve finalizar o instrumento da avaliação!'}
                                visible={showPopupFinalizarInstrumento}
                            />
                        </>
                    </TileGridContainer>
                </>
            )}
        </>
    );
};

export default TileGrid;
