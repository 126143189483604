import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IComponentQuizPontuacaoAlternativas {
    id: number;
    text: string;
    pontos: number;
}

class ComponentQuizPontuacaoAlternativas extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    text: string = '';

    @jsonProperty(Number)
    pontos: number = -1;

    fromJSON(json: IComponentQuizPontuacaoAlternativas, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.text = json.text;
        this.pontos = json.pontos;

        return this;
    }
}

export default ComponentQuizPontuacaoAlternativas;
