import { Drawer } from 'antd';
import React, { useState } from 'react';
import { ListComponentesInteractions, TListComponentesInteractions } from './ListComponentesInteractions';
import styled from 'styled-components';
import Colors from 'enums/Colors';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding-bottom: 42px;
        gap: 1rem;
    `,

    Item: {
        Container: styled.div`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            font-size: 18px;
            border-radius: 10px;
            font-weight: 600;
            background-color: #f2f2f2;
            box-shadow: 4px 4px 10px #00000029;
            height: 60px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                background-color: ${Colors.Grey10};
            }
        `,
    },
};

interface IDrawerComponentsInteractions {
    open: boolean;
    onClose: () => void;
    onClickNewComponent: (component: TListComponentesInteractions) => void;
}

interface IITem {
    dados: TListComponentesInteractions;
    onClick: () => void;
}

const Item = ({ dados, onClick }: IITem) => {
    return (
        <Styled.Item.Container onClick={onClick}>
            {dados.title}
            {dados.icon}
        </Styled.Item.Container>
    );
};

const DrawerComponentsInteractions = ({ open, onClose, onClickNewComponent }: IDrawerComponentsInteractions) => {
    return (
        <Drawer title="Componentes Interativos" placement={'right'} closable={false} onClose={onClose} open={open} key={'left'}>
            <Styled.Container>
                {ListComponentesInteractions.map((dados, index) => (
                    <Item key={index} dados={dados} onClick={() => onClickNewComponent(dados)} />
                ))}
            </Styled.Container>
        </Drawer>
    );
};

export default DrawerComponentsInteractions;
