import Colors from 'enums/Colors';
import React, { useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider, DragPreviewImage } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RiDragDropLine } from 'react-icons/ri';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'; // Ícones de feedback
import CorrespondentModel from 'models/componentInteraction/CorrespondentModel';
import Styled from './CorrespondentPublic.styled';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import { Button, ConfigProvider } from 'antd';
import { SlReload } from 'react-icons/sl';
import { IoMdCheckboxOutline } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { MdFeedback } from 'react-icons/md';

type Item = {
    id: string;
    content: string;
};

interface FlipperProps {
    isFlipped: boolean;
}

const FlipContainer = styled.div`
    perspective: 1000px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Flipper = styled.div<FlipperProps>`
    position: relative;
    width: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${props =>
        props.isFlipped &&
        css`
            transform: rotateY(180deg);
        `}
`;

const Front = styled.div<FlipperProps>`
    width: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    ${props =>
        props.isFlipped &&
        css`
            display: none;
        `}
`;

const Back = styled.div<FlipperProps>`
    width: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 10px;
    flex-grow: 1;

    ${props =>
        !props.isFlipped &&
        css`
            display: none;
        `}
`;

const FlipButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
`;

// Componente de Item Arrastável (Draggable)
const DraggableItem: React.FC<{ item: Item; onRemove?: () => void }> = ({ item, onRemove }) => {
    const [{ isDragging }, dragRef, preview] = useDrag({
        type: 'ITEM',
        item: { id: item.id },
        end: (draggedItem, monitor) => {
            if (!monitor.didDrop() && onRemove) {
                onRemove();
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <>
            <DragPreviewImage connect={preview} src="/path/to/custom/preview/image.png" />
            <div
                className="arrow-box"
                ref={dragRef}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    opacity: isDragging ? 0.7 : 1,
                    padding: 10,
                    border: '1px solid #f0f0f0',
                    borderLeft: '2px solid ' + Colors.Blue,
                    cursor: 'move',
                    marginBottom: '10px',
                    height: '110px',
                    fontSize: 14,
                    lineHeight: '18px',
                    width: "100%",
                    color: Colors.Blue,
                }}
            >
                {item.content}
            </div>
        </>
    );
};

// Componente de Área Soltável (Droppable)
const DroppableItem: React.FC<{
    item: Item;
    linkedItem?: Item;
    submite: boolean;
    onDrop: (leftId: string, rightId: string) => void;
    validationStatus?: boolean;
}> = ({ item, linkedItem, onDrop, validationStatus, submite }) => {
    const [, dropRef] = useDrop({
        accept: 'ITEM',
        drop: (draggedItem: { id: string }) => {
            onDrop(draggedItem.id, item.id);
        },
    });

    return (
        <div
            ref={dropRef}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                paddingTop: '1px',
                minHeight: '110px',
                width: '100%',
                marginLeft: 20,
                position: 'relative',

            }}
        >
            <div
                className="notch-box"
                style={{
                    borderTop: '1px solid #d0d0d0',
                    borderBottom: '1px solid #d0d0d0',
                    borderRight: '1px solid #d0d0d0',
                    borderLeft: linkedItem ? '1px solid #f0f0f0' : '',
                    paddingRight: '2px',
                    width: '100%',
                    height: '110px',
                    display: 'flex',
                    marginBottom: 10,
                    alignItems: 'center',
                    paddingLeft: '22px',
                    fontSize: 14,
                    lineHeight: '18px',
                    color: Colors.Blue,
                    marginRight: 20,
                }}
            >
                {item.content}
            </div>
            {linkedItem ? (
                <DraggableItem item={linkedItem} onRemove={() => onDrop(linkedItem.id, item.id)} />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        fontSize: 14,
                        width: "200px"
                    }}
                >
                      <span>Arraste e solte</span>
                      <RiDragDropLine size={20} />
                </div>
            )}
            {validationStatus !== undefined && (
                <div style={{ position: 'relative',  top: '50%', right: "20px", zIndex: 999 }}>
                    {validationStatus ? <AiOutlineCheckCircle size={26} color="green" /> : <AiOutlineCloseCircle size={26} color="red" />}
                </div>
            )}
        </div>
    );
};

const LeftColumn: React.FC<{
    items: Item[];
    onDrop: (id: string) => void;
}> = ({ items, onDrop }) => {
    const [, dropRef] = useDrop({
        accept: 'ITEM',
        drop: (draggedItem: { id: string }) => {
            onDrop(draggedItem.id);
        },
    });

    return (
        <div
            ref={dropRef}
            style={{
                minHeight: '200px',
                width: '50%', // Modificado para largura responsiva
                borderTop: '1px dashed gray',
                borderLeft: '1px dashed gray',
                borderBottom: '1px dashed gray',
                padding: '5px',
            }}
        >
            {items.map(item => (
                <DraggableItem key={item.id} item={item} />
            ))}
        </div>
    );
};

interface ICorrespodentPublic {
    dados: CorrespondentModel | null;
}

const CorrespodentPublic = ({ dados }: ICorrespodentPublic) => {
    const [leftList, setLeftList] = useState<Item[]>([]);
    const [submit, setSubmite] = useState<boolean>(false);
    const [matches, setMatches] = useState<{ [key: string]: Item | undefined }>({});
    const [validationStatus, setValidationStatus] = useState<{ [key: string]: boolean | undefined }>({});
    const [isFlipped, setIsFlipped] = useState<boolean>(false);

    const handleDrop = (leftId: string, rightId: string) => {
        if (submit) {
            return;
        }

        const itemFromLeft = leftList.find(item => item.id === leftId);
        const currentLinkedItem = matches[rightId];

        if (itemFromLeft) {
            setMatches(prev => ({ ...prev, [rightId]: itemFromLeft }));
            setLeftList(prevLeftList => prevLeftList.filter(item => item.id !== leftId));

            if (currentLinkedItem) {
                setLeftList(prevLeftList => [...prevLeftList, currentLinkedItem]);
            }
        } else {
            const itemFromRight = Object.entries(matches).find(([, item]) => item?.id === leftId);
            if (itemFromRight) {
                const [oldRightId, item] = itemFromRight;
                setMatches(prev => ({
                    ...prev,
                    [rightId]: item,
                    [oldRightId]: currentLinkedItem,
                }));
            }
        }
    };

    const removeFromSlot = (id: string) => {
        if (submit) {
            return;
        }

        const itemFromRight = Object.entries(matches).find(([, item]) => item?.id === id);

        if (itemFromRight) {
            const [rightId, item] = itemFromRight;
            setMatches(prev => ({
                ...prev,
                [rightId]: undefined,
            }));
            setLeftList(prevLeftList => [...prevLeftList, item!]);
        }
    };

    const validateMatching = () => {
        const newValidationStatus: { [key: string]: boolean | undefined } = {};
        dados?.itens?.forEach(item => {
            if (item.fixed) {
                newValidationStatus[item.codeInVinculaty.toString()] = matches[item.codeInVinculaty]?.id === item.codeInVinculaty.toString();
            }
        });
        setValidationStatus(newValidationStatus);
        setSubmite(true);
    };

    const refreshListLeft = () => {
        if (dados) {
            setLeftList(
                dados.itens
                    ? dados.itens
                          .filter(i => !i.fixed)
                          .sort((a, b) => a.ordem - b.ordem)
                          .map(dados => ({ id: dados.codeInVinculaty.toString(), content: dados.text }))
                    : []
            );
        }
    };

    const resetForm = () => {
        setValidationStatus({});
        setMatches({});
        refreshListLeft();
        setIsFlipped(false);
        setSubmite(false);
    };

    useEffect(() => {
        if (dados && Object.keys(matches).length === 0) {
            refreshListLeft();
        }
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.TextContent
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{
                    __html: dados?.pergunta ?? '',
                }}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '16px' }}>
                <DndProvider backend={HTML5Backend}>
                    <FlipContainer>
                        <Flipper isFlipped={isFlipped}>
                            <Front isFlipped={isFlipped}>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <LeftColumn items={leftList} onDrop={removeFromSlot} />
                                    <div
                                        className="column"
                                        style={{
                                            width: '100%',
                                            borderTop: '1px dashed gray',
                                            borderRight: '1px dashed gray',
                                            borderBottom: '1px dashed gray',
                                            paddingRight: '5px',
                                        }}
                                    >
                                        {dados?.itens
                                            ?.filter(f => f.fixed) // Apenas itens fixados
                                            .sort((a, b) => {
                                                const isLinkedA = !!matches[a.codeInVinculaty.toString()]; // Verifica se 'a' está vinculado
                                                const isLinkedB = !!matches[b.codeInVinculaty.toString()]; // Verifica se 'b' está vinculado

                                                // Se ambos tiverem a mesma condição de vínculo, ordena por 'ordem'
                                                if (isLinkedA === isLinkedB) {
                                                    return a.ordem - b.ordem;
                                                }

                                                // Itens não vinculados (isLinkedA = false) vêm antes dos vinculados (isLinkedA = true)
                                                return isLinkedA ? 1 : -1;
                                            })
                                            .map(item => (
                                                <DroppableItem
                                                    key={item.codeInVinculaty}
                                                    item={{ id: item.codeInVinculaty.toString(), content: item.text }}
                                                    submite={submit}
                                                    linkedItem={matches[item.codeInVinculaty.toString()]} // Passa o item vinculado
                                                    onDrop={(leftId: string, rightId: string) => handleDrop(leftId, rightId)}
                                                    validationStatus={validationStatus[item.codeInVinculaty.toString()]}
                                                />
                                            ))}
                                    </div>
                                </div>
                            </Front>
                            <Back isFlipped={isFlipped}>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <h1 style={{ color: Colors.Blue, fontSize: '26px', marginBottom: '12px' }}>Feedback</h1>
                                    <MdFeedback size={22} color={Colors.Blue} />
                                </div>
                                <Styled.TextContent
                                    style={{ margin: 0 }}
                                    dangerouslySetInnerHTML={{
                                        __html: dados?.feedback ?? '',
                                    }}
                                />
                            </Back>
                        </Flipper>
                    </FlipContainer>

                    <Styled.Footer>
                        {!submit ? (
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: Colors.Blue,
                                        colorTextDisabled: '#666666',
                                        colorBgContainerDisabled: '#cccccc',
                                    },
                                }}
                                button={{
                                    style: {},
                                }}
                            >
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={validateMatching}
                                    style={{ width: '150px', borderRadius: '6px' }}
                                    disabled={Object.values(matches).filter(Boolean).length < (dados?.itens?.filter(f => f.fixed).length || 0)}
                                >
                                    Enviar
                                </Button>
                            </ConfigProvider>
                        ) : (
                            <Styled.ContentFooter>
                                <Button onClick={resetForm} size="large">
                                    Refazer
                                    <SlReload />
                                </Button>
                                {!isFlipped && (
                                    <Button onClick={() => setIsFlipped(true)} size="large">
                                        Verificar respostas
                                        <IoMdCheckboxOutline />
                                    </Button>
                                )}
                            </Styled.ContentFooter>
                        )}
                    </Styled.Footer>
                </DndProvider>
            </div>
        </Styled.Container>
    );
};

export default CorrespodentPublic;
