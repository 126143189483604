import ComponentBaralhoModel from 'models/componentInteraction/ComponentBaralho';
import { GenericService } from '../Generic.service';
import { ComponentCenarioGlobal } from 'models/componentInteraction/ComponentCenarioGlobal';
import { ComponentCenario } from 'models/componentInteraction/ComponentCenario';
import { ComponentCenarioAlternativa } from 'models/componentInteraction/ComponentCenarioAlternativa';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';

export class ComponentCenarioService extends GenericService {
    readonly URI: string = '/v1/componentCenario';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public insert(idComponente: number, model: ComponentCenarioGlobal) {
        return this.post('' + idComponente, model);
    }

    public update(model: ComponentCenario) {
        return this.put('updateOne', model);
    }

    public updateAlternativa(model: ComponentCenarioAlternativa) {
        return this.put('updateOne/alternativa', model);
    }

    public updateAnexo(idComponente: number, model: ComponentInteractionAnexo) {
        return this.put('anexo/' + idComponente, model);
    }

    public deleteCenario(idCenario: number) {
        return this.delete(idCenario);
    }

    public deleteAlternativa(idAlt: number) {
        return this.deleteByUrl(idAlt, 'alternativa');
    }
}
