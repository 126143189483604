import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import { ComponentCenarioAlternativa } from './ComponentCenarioAlternativa';

interface IComponentCenario {
    id: number;
    title: string;
    alternativas: ComponentCenarioAlternativa[];
}

export class ComponentCenario extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    title: string = '';

    alternativas: ComponentCenarioAlternativa[] = [];

    fromJSON(json: IComponentCenario, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.title = json.title;
        this.alternativas = json.alternativas;

        return this;
    }
}
