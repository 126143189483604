import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import ComponentQuizPontuacaoFeedback from './ComponentQuizPontuacaoFeedback';
import ComponentQuizPontuacao from './ComponentQuizPontuacao';

interface IComponentQuizPontuacaoGlobal {
    quizzes: ComponentQuizPontuacao[];
    feedbacks: ComponentQuizPontuacaoFeedback[];
}

class ComponentQuizPontuacaoGlobal extends Serializable {
    quizzes: ComponentQuizPontuacao[] = [];

    feedbacks: ComponentQuizPontuacaoFeedback[] = [];

    fromJSON(json: IComponentQuizPontuacaoGlobal, settings?: Partial<SerializationSettings>): this {
        this.quizzes = json.quizzes;
        this.feedbacks = json.feedbacks;

        return this;
    }
}

export default ComponentQuizPontuacaoGlobal;
