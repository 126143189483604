import {
    cursoRealizandoEscolhasGestor,
    cursoRealizandoEscolhasProfessorEnsinoMedio,
    cursoRealizandoEscolhasProfessor9ano,
    idCursoRealizandoEscolhasGestor,
    idCursoRealizandoEscolhasProfessor9ano,
    idCursoRealizandoEscolhasProfessorEnsinoMedio,
} from 'constants/EnvironmentData';
import { IntegracaoLmsService } from 'core/http/service/IntegracaoLMS.service';
import { getUserRole } from 'core/util/KeycloakHelp';
import { MatriculaLMSEnum } from 'enums/MatriculaLMSEnum';
import { ConcluidoNaoConcluidoEnum } from 'enums/ConcluidoNaoConcluidoEnum';
import { RoleEnum } from 'enums/RoleEnum';
import UsuarioDTO from 'models/UsuarioDTO';
import { useHistory } from 'react-router-dom';
import { ToastError } from 'components/Toaster';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { Ocupation } from 'enums/Ocupation';
import { ProfileService } from 'core/http/service/Profile.service';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';

export const buscarOcupacaoPorPerfil = (usuario: PerfilUsuario | null) => {
    const ocupacao = usuario?.ocupacao;

    if (ocupacao === Ocupation.Professor) {
        return 'A';
    } else if (
        ocupacao === Ocupation.OrientadorPedagogico ||
        ocupacao === Ocupation.CoordenadorPedagogico ||
        ocupacao === Ocupation.ViceDiretor ||
        ocupacao === Ocupation.Diretor ||
        ocupacao === Ocupation.GestorEscolar
    ) {
        return 'B';
    } else {
        return 'C';
    }
};

export const buscarCategoriaPorPerfil = async (usuarioLogado: UsuarioDTO) => {
    let role = RoleEnum.Unknown;

    if (usuarioLogado) {
        if (usuarioLogado.roles.length <= 3) {
            const profileService = new ProfileService();
            const { data } = await profileService.findByUsername(usuarioLogado.username);

            const _perfil = new PerfilUsuario().fromJSON(data);
            role = _perfil.role;
        }

        const roleUser: RoleEnum = getUserRole(usuarioLogado.roles)[0];
        role = roleUser ? roleUser : role;

        if (role == RoleEnum.Professor || role == RoleEnum.Outro) {
            return 'A';
        } else if (role == RoleEnum.CoordenadorNaoParceiro || role == RoleEnum.Diretor || role == RoleEnum.TecnicoEducacao) {
            return 'B';
        }
    }
};

export const redirectToTrilhaFormativaLMS = async (role: string, is9Ano: boolean, usuarioLogado: UsuarioDTO, callbackLoading: any) => {
    const history = useHistory();

    if (role == 'A') {
        if (is9Ano) {
            history.push('/recursos/jornada-realizando-escolhas/trilha-formativa-professor-9ano');
        } else {
            history.push('/recursos/jornada-realizando-escolhas/trilha-formativa-professor-ensino-medio');
        }
    } else if (role == 'B') {
        history.push('/recursos/jornada-realizando-escolhas/trilha-formativa-gestor');
    }
};

export const matricularUsuario = async (idCurso: number, usuarioLogado: UsuarioDTO, callbackLoading: any) => {
    const integracaoLmsService = new IntegracaoLmsService();

    try {
        callbackLoading(true);

        let status: MatriculaLMSEnum = MatriculaLMSEnum.NaoMatriculado;
        const result = await integracaoLmsService.statusMatricula(usuarioLogado.username ?? '', idCurso);
        if (result.status === 200) {
            status = result.data;
        }
        if ((status = MatriculaLMSEnum.NaoMatriculado)) {
            await integracaoLmsService.matricular(usuarioLogado.username ?? '', idCurso);
        }
    } catch (e: any) {
        console.log('err', e);
        ToastError('Aconteceu um erro ao realizar a matricula na trilha formativa. Por favor, entre em contato através do "Fale conosco" ');
    } finally {
        callbackLoading(false);
    }
};

export const checarConclusaoTrilhaFormativa = async (idCurso: string[], usuarioLogado: UsuarioDTO, userRole: string) => {
    const integracaoLmsService = new IntegracaoLmsService();

    let retorno: ConcluidoNaoConcluidoEnum = ConcluidoNaoConcluidoEnum.NaoConcluido;

    try {
        if (userRole == 'A') {
            const NonoAnoConcluido = await integracaoLmsService.verificarCursoConcluido(parseInt(idCurso[0]), usuarioLogado.username ?? '');
            const EnsinoMedioConcluido = await integracaoLmsService.verificarCursoConcluido(parseInt(idCurso[1]), usuarioLogado.username ?? '');

            if (NonoAnoConcluido.status === 200 && EnsinoMedioConcluido.status === 200) {
                if (
                    NonoAnoConcluido.data === ConcluidoNaoConcluidoEnum.Concluido ||
                    EnsinoMedioConcluido.data == ConcluidoNaoConcluidoEnum.Concluido
                )
                    retorno = ConcluidoNaoConcluidoEnum.Concluido;
            }
        } else {
            const id = parseInt(idCurso[0]);
            const result = await integracaoLmsService.verificarCursoConcluido(id, usuarioLogado.username ?? '');
            if (result.status === 200) {
                retorno = result.data;
            }
        }
    } catch (e: any) {
        ToastError('Aconteceu um erro ao verificar sua matricula na trilha formativa. Por favor, entre em contato através do "Fale conosco" ');
    }

    return retorno;
};

export const redirectToInstrumento = async (
    userRole: string,
    callbackErro: (show: boolean) => void,
    usuarioLogado: UsuarioDTO,
    callbackSucesso: () => void
) => {
    const serviceRecursosCostumizados = new AdminRecursosCostumizados();

    if (userRole == 'A') {
        const res9ano = await serviceRecursosCostumizados.listByEtapasAndConteudosResumidos(34);

        if (res9ano.status === 200 && res9ano.data.isConcluido) {
            callbackSucesso();
        }

        const resEnsMedio = await serviceRecursosCostumizados.listByEtapasAndConteudosResumidos(35);

        if (resEnsMedio.status === 200 && resEnsMedio.data.isConcluido) {
            callbackSucesso();
        }
    } else if (userRole == 'B') {
        const resGestor = await serviceRecursosCostumizados.listByEtapasAndConteudosResumidos(36);

        if (resGestor.status === 200 && resGestor.data.isConcluido) {
            callbackSucesso();
        }
    } else {
        callbackErro(true);
    }
};
