import Colors from 'enums/Colors';
import ComponentTimeLine from 'models/componentInteraction/ComponentTimeLine';
import React from 'react';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 76px 0px;
        max-width: 720px;
    `,

    TimeLine: {
        Container: styled.div<{ color: string }>`
            display: flex;
            flex-direction: column;
            width: 636px;
            padding: 24px;
            gap: 8px;
            border: 0px 0px 0px 2px;
            border-left: 2px solid ${({ color }) => (color ? color : Colors.Blue)};
            background-color: #f5f5f5;
        `,

        dataText: styled.h2`
            font-size: 12;
        `,

        title: styled.h1<{ color: string }>`
            font-weight: 600;
            font-size: 24px;
            color: ${({ color }) => (color ? color : Colors.Blue)};
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        `,

        Line: styled.div`
            width: 2px;
            height: 80px;
            gap: 0px;
            border: 2px 0px 0px 0px;
            opacity: 0px;
            border: 1px solid #999999;
        `,
    },
};

interface IComponentTimeLinesPublic {
    dados: ComponentTimeLine[];
    color: string;
}

const ComponentTimeLinesPublic = ({ dados, color }: IComponentTimeLinesPublic) => {
    return (
        <Styled.Container>
            {dados?.map((tl: ComponentTimeLine, index: number) => (
                <Styled.TimeLine.Column key={index}>
                    {index > 0 && <Styled.TimeLine.Line />}
                    <Styled.TimeLine.Container color={color}>
                        <Styled.TimeLine.dataText>{tl.title}</Styled.TimeLine.dataText>
                        <Styled.TimeLine.title color={color}>{tl.titleEvent}</Styled.TimeLine.title>
                        <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: tl.description }} />
                    </Styled.TimeLine.Container>
                </Styled.TimeLine.Column>
            ))}
        </Styled.Container>
    );
};

export default ComponentTimeLinesPublic;
