import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IComponentCenarioAlternativa {
    id: number;
    text: string;
    feedback: string;
}

export class ComponentCenarioAlternativa extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    text: string = '';

    @jsonProperty(String)
    feedback: string = '';

    fromJSON(json: IComponentCenarioAlternativa, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.text = json.text;
        this.feedback = json.feedback;

        return this;
    }
}
