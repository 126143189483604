import { Checkbox } from 'antd';
import Colors from 'enums/Colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';
import { MdArrowForwardIos, MdArrowRight, MdArrowRightAlt } from 'react-icons/md';
import { OutlineButton } from 'components/UI/Buttons';
import ComponentQuizPontuacaoGlobal from 'models/componentInteraction/ComponentQuizPontuacaoGlobal';
import ComponentQuizPontuacao from 'models/componentInteraction/ComponentQuizPontuacao';
import ComponentQuizPontuacaoFeedback from 'models/componentInteraction/ComponentQuizPontuacaoFeedback';
import ComponentQuizPontuacaoAlternativas from 'models/componentInteraction/ComponentQuizPontuacaoAlternativas';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 27px 16px;
        border: 1px solid #f0f0f0;
        margin-bottom: 22px;
    `,

    Quantity: styled.p`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 600;
        line-height: 26.4px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    Option: {
        Container: styled.div`
            display: flex;
            align-items: center;
            background-color: #fafafa;
            min-height: 58px;
            padding: 0px 22px;

            gap: 8px;
        `,

        Text: styled.h2`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 400;
            line-height: 25.2px;
            text-align: left;
            text-decoration-skip-ink: none;
        `,
    },

    Button: styled.button`
        width: 220px;
        height: 46px;
        border-radius: 20px;
    `,

    Feedback: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
        `,

        Pontos: styled.h2`
            //styleName: Base/H2;
            font-family: 'Barlow';
            font-size: 36px;
            font-weight: 700;
            line-height: 43.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};
        `,

        ContentText: styled.div`
            background-color: #fafafa;
            min-height: 83px;
            padding: 16px;
            text-align: center;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 400;
            line-height: 25.2px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #242424;
        `,
    },
};

interface IOption {
    dados: ComponentQuizPontuacaoAlternativas;
    isChecked: boolean;
    multiple: boolean;
    onChange: (checked: boolean) => void;
}

const Option = ({ dados, isChecked, multiple, onChange }: IOption) => {
    return (
        <Styled.Option.Container>
            <input
                name="option"
                type={multiple ? 'checkbox' : 'radio'}
                style={{ transform: 'scale(2)', marginRight: '22px', cursor: 'pointer' }}
                checked={isChecked}
                onChange={e => onChange(e.target.checked)}
            />
            <Styled.Option.Text>{dados.text}</Styled.Option.Text>
        </Styled.Option.Container>
    );
};

interface IComponentQuizPontucaoPublic {
    dados: ComponentQuizPontuacaoGlobal;
}

const ComponentQuizPontucaoPublic = ({ dados }: IComponentQuizPontucaoPublic) => {
    const [quizzes, setQuizzes] = useState<ComponentQuizPontuacao[] | null>(null);
    const [feedbacks, setFeedbacks] = useState<ComponentQuizPontuacaoFeedback[] | null>(null);
    const [openFeedback, setOpenFeedback] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<number[]>([]); // Armazena os ids das alternativas selecionadas
    const [addIdRadio, setAddIdRadio] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);

    const resetQuizzes = () => {
        setOpenFeedback(false);
        setSelectedIds([]);
        setIndex(0);
    };

    const HandleFeedback = (): JSX.Element => {
        let totalPoints = 0;

        // Verifica se quizzes estão carregados
        if (quizzes) {
            // Percorre cada quiz
            quizzes.forEach(quiz => {
                // Percorre cada alternativa do quiz
                quiz.alternativas.forEach(alt => {
                    // Verifica se o ID da alternativa está na lista de IDs selecionados
                    if (selectedIds.includes(alt.id)) {
                        // Adiciona os pontos da alternativa ao total
                        totalPoints += alt.pontos;
                    }
                });
            });
        }

        const feedSelected: ComponentQuizPontuacaoFeedback | undefined = feedbacks?.find(
            f => totalPoints > f.nivelMin && totalPoints < f.nivelMax
        );

        return (
            <Styled.Feedback.Container>
                <Styled.Feedback.Pontos>
                    {feedSelected?.nivelMin} - {feedSelected?.nivelMax}
                </Styled.Feedback.Pontos>
                <Styled.Feedback.ContentText>{feedSelected?.text}</Styled.Feedback.ContentText>
                <OutlineButton onClick={resetQuizzes}>Tentar novamente</OutlineButton>
            </Styled.Feedback.Container>
        );
    };

    const next = () => {
        if (quizzes) {
            if (index + 1 < quizzes?.length) {
                setIndex(index + 1);
                setAddIdRadio(false);
            } else if (index + 1 == quizzes.length) {
                setOpenFeedback(true);
            }
        }
    };

    const handleOptionChange = (id: number, isMultiple: boolean, checked: boolean) => {
        setSelectedIds(prev => {
            if (isMultiple) {
                // Se for múltipla, adiciona o novo ID ou remove o ID existente
                if (checked) {
                    return [...prev, id];
                } else {
                    return prev.filter(selectedId => selectedId !== id);
                }
            } else {
                // Para opções de rádio (single choice)
                if (checked) {
                    // Se a nova opção está sendo selecionada
                    if (!addIdRadio) {
                        setAddIdRadio(true);
                        return [...prev, id];
                    } else {
                        return [...prev.slice(0, prev.length - 1), id];
                    }

                    // Modifica o último ID adicionado
                } else {
                    // Se a opção foi desmarcada, não fazemos nada
                    return prev;
                }
            }
        });
    };

    useEffect(() => {
        if (dados) {
            setQuizzes(dados.quizzes);
            setFeedbacks(dados.feedbacks);
        }
    }, [dados]);

    return (
        <Styled.Container>
            {quizzes &&
                !openFeedback &&
                quizzes.map((q, i) => {
                    if (i === index) {
                        return (
                            <>
                                <Styled.Quantity>
                                    Questão {i + 1}/{quizzes.length}
                                </Styled.Quantity>
                                <Styled.Title>{q.title}</Styled.Title>
                                <TextDangerouslySetInnerHtml
                                    style={{ margin: 0 }}
                                    dangerouslySetInnerHTML={{
                                        __html: q.description,
                                    }}
                                />
                                {q.alternativas.map((alt, i) => (
                                    <Option
                                        key={i}
                                        dados={alt}
                                        multiple={q.multipleChoice}
                                        isChecked={selectedIds.includes(alt.id)} // Verifica se o ID está selecionado
                                        onChange={checked => handleOptionChange(alt.id, q.multipleChoice, checked)} // Passa o ID e os pontos
                                    />
                                ))}

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                    <OutlineButton onClick={next}>
                                        Avançar <MdArrowForwardIos size={16} />
                                    </OutlineButton>
                                </div>
                            </>
                        );
                    }
                })}

            {openFeedback && <HandleFeedback />}
        </Styled.Container>
    );
};

export default ComponentQuizPontucaoPublic;
