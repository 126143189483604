import React, { useEffect, useState } from 'react';
import { Button, InputNumber, message, Popconfirm, Select } from 'antd';
import styled from 'styled-components';
import type { PopconfirmProps } from 'antd';
import { AdminGestaoPaginasMode } from 'pages/gestao_paginas/AdminGestaoPaginasContextWrapper';

const Styled = {
    Container: styled.div`
        display: flex;
        gap: 1rem;
    `,
};

interface IMenuComponentInteraction {
    onClickDelete: () => void;
    onClickSave: () => void;
    mode: boolean;
    myOrdem: number;
    length: number;
    handleOrdem: (newIndex: number) => void;
    loadingButton: boolean;
    ocultarButtonSave?: boolean;
}

const MenuComponentInteraction = ({
    onClickDelete,
    onClickSave,
    mode,
    length,
    myOrdem,
    handleOrdem,
    loadingButton,
    ocultarButtonSave,
}: IMenuComponentInteraction) => {
    const [myOrder, setMyOrder] = useState<number>(myOrdem);

    const [loadingButtonCreate, setLoadingButtonCreate] = useState<boolean>(false);
    const [loadingButtonDelete, setLoadingButtonDelete] = useState<boolean>(false);

    const handleChange = (value: { value: string; label: React.ReactNode }) => {
        handleOrdem(parseInt(value.value));
        setMyOrder(parseInt(value.value));
    };

    useEffect(() => {
        setMyOrder(myOrdem);
    }, [myOrdem]);

    useEffect(() => {
        if (loadingButtonCreate) setLoadingButtonCreate(loadingButton);
        if (loadingButtonDelete) setLoadingButtonDelete(loadingButton);
    }, [loadingButton]);

    return (
        <Styled.Container>
            {mode && !ocultarButtonSave && (
                <>
                    <Button
                        loading={loadingButtonCreate}
                        onClick={() => {
                            setLoadingButtonCreate(true);
                            onClickSave();
                        }}
                        type="primary"
                    >
                        Salvar alterações
                    </Button>
                </>
            )}
            <Popconfirm
                title="Você deseja excluir este componente?"
                description="Após excluir não tem como fazer a recuperação!"
                onConfirm={() => {
                    setLoadingButtonDelete(true);
                    onClickDelete();
                }}
                okText="Sim, quero excluir"
                cancelText="Não"
            >
                <Button loading={loadingButtonDelete} danger>
                    Excluir componente
                </Button>
            </Popconfirm>
            <Select
                labelInValue
                value={{ value: myOrder.toString(), label: myOrder }}
                style={{ width: 120 }}
                onChange={handleChange}
                options={Array.from({ length })
                    .filter(f => f !== myOrdem)
                    .map((n: any, i: number) => ({ label: i + 1, value: i }))}
            />
        </Styled.Container>
    );
};

export default MenuComponentInteraction;
