import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import { ComponentCenario } from './ComponentCenario';
import { ComponentInteractionAnexo } from './ComponentInteractionAnexo';

interface IComponentCenarioGlobal {
    cenarios: ComponentCenario[];
    anexo: ComponentInteractionAnexo;
}

export class ComponentCenarioGlobal extends Serializable {
    cenarios: ComponentCenario[] = [];

    anexo: ComponentInteractionAnexo = new ComponentInteractionAnexo();

    fromJSON(json: IComponentCenarioGlobal, settings?: Partial<SerializationSettings>): this {
        this.cenarios = json.cenarios;
        this.anexo = json.anexo;

        return this;
    }
}
