import Loading from 'components/Loading';
import Row from 'components/Row';
import { ToastSuccess, ToastError } from 'components/Toaster';
import { PrimaryButton } from 'components/UI/Buttons';
import copy from 'copy-to-clipboard';
import AdminRecursosCostumizadosService from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { KeycloakLoginOptions } from 'keycloak-js';
import { cloneDeep } from 'lodash';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { ComentariosRecursosCostumizados, ComentarioSimplificado } from 'models/recursos-costumizados/ComentariosRecursosCostumizados';
import { ConteudoRecursoCustomizado as Conteudo, ConteudoRecursoCustomizado } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';
import { ItemRecursosCostumizado as Item } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { LogAcaoRecursoEnum, LogAcaoRecursoCustomizado, browserGenerateKey } from 'models/recursos-costumizados/LogAcaoRecursoCustomizado';
import { base64PDFToFile } from 'pages/admin/utils/base64ToFile';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { AiOutlineShareAlt, AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsEmojiSmile, BsCheckCircle, BsCheck2Circle } from 'react-icons/bs';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { useParams, useHistory } from 'react-router-dom';
import { isMobileDevice } from 'react-select/src/utils';
import { useKeycloak } from '@react-keycloak/web';
import { RecursosContext } from '../context/RecursosContext';
import IconCheck from './assets/iconCheck.svg';
import IconDownload from './assets/iconDownload.svg';
import PlusCircle from './assets/plusCircle.svg';
import BarProgressContent from './components/barProgress/BarProgressContent';
import RecursosComment from './components/comment/RecursosComment';
import RecursoMediaSwitch from './components/media-switch/RecursoMediaSwitch';
import RecursosRateModal from './components/rates/RecursosRateModal';
import Styled from './RecursosConteudoPage.styled';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { ArquivoPrincipalEnum } from 'enums/ArquivoPrincipalEnum';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import Colors from 'enums/Colors';
import { fetchPalavrasProibidas, validatePalavraProibida } from 'helpers/Functions';
import ModalPlayAudio from 'pages/intermediarias/alfabetizacao360Detail/subcomponents/ModalPlayAudio';
import styled from 'styled-components';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import AmpliarImg from 'components/ampliarImg/AmpliarImg';
import { notification, Result } from 'antd';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import type { CollapseProps } from 'antd';
import { Collapse, ConfigProvider } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ComponentQuizModel from 'models/componentInteraction/ComponentQuiz';
import QuizInteractionPublic from './components/quiz/QuizInteractionPublic';
import CorrespondentPublic from './components/correspondent/CorrespondentPublic';
import CarrosselInteractionPublic from './components/carrossel/CarrosselInteractionPublic';
import ComponentAbasPublic from './components/abas/ComponentAbasPublic';
import ComponentBaralhoPublic from './components/baralho/ComponentBaralhoPublic';
import ReguaAvaliativaPublic from './components/reguaAvaliativa/ReguaAvaliativaPublic';
import ComponentTimeLinesPublic from './components/timelines/ComponentTimeLinesPublic';
import ComponentQuizPontucaoPublic from './components/quiz/QuizPontuacaoPublic';
import ComponentCenarioPublic from './components/cenario/ComponentCenarioPublic';

const StyledCaretRightOutlined = styled(CaretRightOutlined)``;

export interface IRecursoConteudoPage {}

const RecursoConteudoPage = ({}: IRecursoConteudoPage) => {
    const history = useHistory();
    const { keycloak } = useKeycloak();

    const [api, contextHolder] = notification.useNotification();

    const [isDropped, setDropped] = useState<boolean>(false);
    const [commentInput, setCommentInput] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const params: any = useParams();
    const [comments, setComments] = useState<ComentariosRecursosCostumizados[]>([]);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [palavrasProibidas, setPalavrasProibidas] = useState<string[]>();
    const [isPalavrasProibidas, setIsPalavrasProibidas] = useState<boolean>(false);
    const [blockListenConteudo, setBlockListenConteudo] = useState<boolean>(true);

    //
    const [numberLoadingRecurso, setLoadingRecurso] = useState<number>(-1);

    const [showModal, setShowModal] = useState(false);
    const [favorited, setFavorited] = useState(false);

    const [conteudo, setConteudo] = useState<Conteudo | null>(null);
    const [loadingConteudo, setLoadingConteudo] = useState<boolean>(false);
    const [conteudos, setConteudos] = useState<Conteudo[]>([]);
    const [item, setItem] = useState<Item>();
    const { itemRecurso, loading: loadingItem, pagina } = useContext(RecursosContext.Context);
    const [isNescessityPlayAudio, setNescessityPlayAudio] = useState<boolean>(false);

    const [conteudoSequenciais, setConteudoSequenciais] = useState<boolean>(false);

    const serviceRecursosCostumizados = new AdminRecursosCostumizadosService();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);

    const [isBlockConcluirConteudo, setBlockConcluirConteudo] = useState<boolean>(false);

    //pedding next conteudo
    const [peddingLoading, setPeddingLoading] = useState<boolean>(true);

    const timePedding = () => {
        setPeddingLoading(true);

        setTimeout(() => {
            setPeddingLoading(false);
        }, 5000);
    };

    const [porcent, setPorcent] = useState<number>(80);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentImgSrc, setCurrentImgSrc] = useState('');

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const openModal = (imgSrc: string) => {
        setCurrentImgSrc(imgSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentImgSrc('');
    };

    const getPrevLevel = () => {
        const paths = location.pathname.split('/');
        let path = '';

        for (let i = 0; i < paths.length - 2; i++) {
            if (paths[i].length > 0) {
                path += paths[i] + '/';
            }
        }

        return path;
    };

    const MessageError = (msg: string) => {
        api['error']({
            style: { backgroundColor: Colors.Grey10 },
            message: <h1 style={{ fontWeight: 700 }}>ACESSO NEGAGADO!</h1>,
            description: <p style={{ fontSize: 16, fontWeight: 500 }}>{msg}</p>,
            duration: 5,
        });
    };

    const canAccessContent = (nextContent?: Conteudo, next?: Boolean): boolean => {
        if (nextContent?.codigo == conteudos[0]?.codigo) return true;
        setLoadingRecurso(nextContent?.codigo ?? -1);

        if (conteudoSequenciais) {
            for (let i = 1; i < conteudos.length; i++) {
                if (conteudos[i].codigo == nextContent?.codigo) {
                    if (next || conteudos[i - 1].isConcluido || nextContent?.isConcluido || conteudos[i - 1].oculto === true) {
                        return true;
                    } else {
                        MessageError(`Você precisa concluir o "${conteudos[i - 1].nome}" para prosseguir.`);
                        setPeddingLoading(false);
                    }
                }
            }
        } else {
            return true;
        }

        return false;
    };

    const gotoContent = (conteudo: Conteudo, next?: Boolean) => {
        if (next && isBlockConcluirConteudo) {
            return MessageError('Você precisa concluir as obrigações antes de prosseguir!');
        }

        if (canAccessContent(conteudo, next)) {
            setLoadingRecurso(conteudo.codigo);
            setLoadingConteudo(true);
            setPeddingLoading(true);
            const newPath = `/${getPrevLevel()}${params.etapaId}/${conteudo.url}`;

            if (!keycloak.authenticated) {
                const options: KeycloakLoginOptions = {
                    redirectUri: window.location.origin + '/login?' + newPath,
                };

                const _url = keycloak.createRegisterUrl(options);
                window.location.assign(_url);
            } else {
                if (isNescessityPlayAudio) {
                    window.location.assign(newPath);
                } else {
                    history.push(newPath);
                }
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [params.conteudoId]);

    const urlRedirectItem = (): string => {
        const url = window.location.href;

        const partes = url.split('/');

        // Verificar quantas partes existem
        const quantidadeDePartes = partes.length;

        // Montar a nova URL até o penúltimo "/"
        return partes.slice(0, quantidadeDePartes - 2).join('/');
    };

    const copyToClipboard = () => {
        copy(urlRedirectItem());
        ToastSuccess('Link do conteúdo copiado!');
    };

    useEffect(() => {
        setLoading(true);

        const itemUrl = params.itemId;
        const etapaUrl = params.etapaId;
        const conteudoUrl = params.conteudoId;

        const item = itemRecurso;
        setConteudoSequenciais(item?.conteudosSequenciais ?? false);

        if (item) {
            for (const etapa of item.etapas) {
                if (etapaUrl == etapa.url.replace(/\//g, '')) {
                    for (const conteudo of etapa.conteudos) {
                        if (conteudoUrl == conteudo.url.replace(/\//g, '')) {
                            fetchContent(conteudo.codigo);
                            setItem(item);
                            setConteudos(etapa.conteudos.filter(c => c.oculto === false));
                        }
                    }
                }
            }
            setLoading(false);
        }
    }, [itemRecurso, blockListenConteudo]);

    useEffect(() => {
        const conteudoUrl = params.conteudoId;
        const etapaUrl = '/' + params.etapaId;

        const item = itemRecurso;

        if (item) {
            const etapas: number = item?.etapas.length ?? 0;

            if (item.etapasSequenciais) {
                // Validando se o conteudo possui etapa sequencial e se a etapa anterior foi concluida (caso possua uma etapa anterior).
                for (let i = 0; i < etapas; i++) {
                    const myEtapa = item.etapas[i].url == etapaUrl;

                    if (myEtapa && i > 0 && !item.etapas[i - 1].isConcluido) {
                        window.location.href = urlRedirectItem();
                    }
                }
            }

            if (item.conteudosSequenciais) {
                // Validando se o conteudo anterior foi concluído, caso não redireciona para o item do recurso atual.
                for (let j = 0; j < etapas; j++) {
                    const myEtapa2 = item.etapas[j].url == etapaUrl;

                    if (myEtapa2) {
                        const myConteudo = item.etapas[j].conteudos.findIndex((c: Conteudo) => c.url.includes(conteudoUrl));

                        if (myConteudo === 0) {
                            setBlockListenConteudo(false);
                            return;
                        } else if (!item.etapas[j].conteudos[myConteudo - 1].isConcluido) {
                            window.location.href = urlRedirectItem();
                            return;
                        }
                    }
                }
            }
            setBlockListenConteudo(false);
        }
    }, [itemRecurso]);

    const validatePalavras = () => {
        setIsPalavrasProibidas(validatePalavraProibida(palavrasProibidas ?? [''], commentInput));
    };

    useEffect(() => {
        validatePalavras();
    }, [commentInput]);

    const fetchPP = async () => {
        setPalavrasProibidas(await fetchPalavrasProibidas());
    };

    useEffect(() => {
        fetchPP();
    }, []);

    const isValidComment = (): boolean => {
        return commentInput.trim().length > 2;
    };

    const fetchComments = async () => {
        setComments([]);

        if (conteudo && conteudo.codigo) {
            const service = await serviceRecursosCostumizados.listarComentariosPorConteudo(conteudo.codigo);
            const data = service.data;
            const _comments = Array.from(data).map((comment: any) => new ComentariosRecursosCostumizados().fromJSON(comment));

            setComments(_comments);
        }
    };

    const fetchContent = async (conteudoId: number) => {
        if (conteudoId && !blockListenConteudo) {
            setLoadingConteudo(true);
            const service = await serviceRecursosCostumizados.buscarConteudo(conteudoId, true);
            const data = service.data;
            setConteudo(new ConteudoRecursoCustomizado().fromJSON(data));
            setLoadingConteudo(false);
        }
    };

    const sendComment = async () => {
        setLoading(true);

        if (conteudo && conteudo.codigo) {
            const dados: ComentarioSimplificado = new ComentarioSimplificado().fromJSON({
                codigoConteudo: conteudo.codigo,
                comentario: commentInput,
            });
            await serviceRecursosCostumizados.enviarComentario(dados);

            await fetchComments();
            setCommentInput('');
        }

        setLoading(false);
    };

    const toSendComment = async () => {
        if (keycloak.authenticated) sendComment();
    };

    const isFirst = (): boolean => {
        if (conteudos.length == 0 || conteudo == null) return false;
        else return conteudos[0].codigo == conteudo.codigo;
    };

    const isLast = (): boolean => {
        if (conteudos.length == 0 || conteudo == null) return false;
        else return conteudos[conteudos.length - 1].codigo == conteudo?.codigo;
    };

    const gotoBackLevel = () => {
        let newPath = getPrevLevel();
        newPath = newPath.substring(0, newPath.length - 1);
        history.push('/' + newPath);
    };

    const markDone = async (): Promise<boolean> => {
        setLoadingSave(true);
        if (conteudo && conteudo.codigo) {
            const { status } = await serviceRecursosCostumizados.marcarConcluido(conteudo.codigo);

            if (status < 300) {
                registerAction(LogAcaoRecursoEnum.CONCLUSAO_CONTEUDO);

                const cloneConteudo = cloneDeep(conteudo);
                const cloneConteudos = cloneDeep(conteudos);
                cloneConteudo.isConcluido = true;

                for (let i = 0; i < cloneConteudos.length; i++)
                    if (cloneConteudos[i].codigo == conteudo.codigo) {
                        cloneConteudos[i] = cloneConteudo;
                    }

                porcentagem();
                setConteudos(cloneConteudos);
                setConteudo(cloneConteudo);
                setLoadingSave(false);
                return true;
            }
            return false;
        }
        return false;
    };

    const markDoneAndNext = async () => {
        const next = await markDone();
        gotoNext(next);
    };

    const favorite = async () => {
        if (conteudo && conteudo.codigo) {
            if (!favorited) {
                await serviceRecursosCostumizados.favoritarConteudo(conteudo.codigo);
                ToastSuccess(`Conteúdo favoritado com sucesso!`);
                setFavorited(true);
            } else {
                await serviceRecursosCostumizados.desfavoritarConteudo(conteudo.codigo);
                setFavorited(false);
            }
        }
    };

    const fetchFavorite = async () => {
        if (conteudo && conteudo.codigo) {
            const { status } = await serviceRecursosCostumizados.buscaFavoritado(conteudo.codigo);

            if (status < 300) {
                setFavorited(true);
            } else {
                setFavorited(false);
            }
        }
    };

    const gotoNext = (next?: Boolean) => {
        if (next && isBlockConcluirConteudo) {
            return MessageError('Você precisa concluir as obrigações antes de prosseguir!');
        }
        setPeddingLoading(true);
        const currentIndex = conteudos.findIndex(c => c.codigo === conteudo?.codigo);
        if (currentIndex !== -1 && currentIndex + 1 < conteudos.length) {
            const nextContent = conteudos[currentIndex + 1];
            gotoContent(nextContent, next);
        }
    };

    const gotoPrev = () => {
        const currentIndex = conteudos.findIndex(c => c.codigo === conteudo?.codigo);

        if (currentIndex !== -1 && currentIndex - 1 >= 0) {
            const previousContent = conteudos[currentIndex - 1];
            gotoContent(previousContent);
        } else {
            gotoContent(conteudos[conteudos.length - 1]);
        }
    };

    const porcentagem = () => {
        let numero: number = 100 / conteudos.length;
        let porcentagem: number = 0;

        for (const conteudo of conteudos) {
            if (conteudo.isConcluido) porcentagem += numero;
        }

        return Math.floor(Math.round(porcentagem));
    };

    const downloadPdf = async () => {
        setLoadingDownload(true);
        try {
            const { data } = await serviceRecursosCostumizados.fetchPDF(conteudo?.filePath ?? '');
            base64PDFToFile(data, conteudo?.filePath ?? 'material');
            registerAction(LogAcaoRecursoEnum.DOWNLOAD_MATERIAL);
        } catch (error) {
            ToastError('Aconteceu algum erro ao tentar baixar o material');
        }

        setLoadingDownload(false);
    };

    const registerFirstAccess = async () => {
        const response = await serviceRecursosCostumizados.logFirstAcesso(item?.codigo ?? -1);

        if (response.status === 201) {
            console.log('Log registrado com sucesso!');
        }
    };

    const registerAction = (acao: LogAcaoRecursoEnum) => {
        if (userProfile) {
            const log: LogAcaoRecursoCustomizado = new LogAcaoRecursoCustomizado().fromJSON({
                codigo_usuario: userProfile?.keycloakId,
                nome_recurso: pagina?.nome,
                url_pagina: location.pathname,
                codigo_conteudo: conteudo?.codigo.toString(),
                codigo_item: item?.codigo?.toString(),
                acao: acao,
                especificacao_item: conteudo?.nome,
                browser_key: browserGenerateKey(),
            });
            serviceRecursosCostumizados.registrarAcao(log);
        }
    };

    const getAnexoPrincipal = (value: ArquivoPrincipalEnum, position: ArquivoPrincipalEnum): JSX.Element => {
        return (
            <>
                {value === position && conteudo?.filePath && conteudo?.tipoAnexo?.codigo !== 4 && conteudo?.tipoAnexo?.codigo ? (
                    <RecursoMediaSwitch marginBottom="20px" anexoUrl={conteudo.filePath} />
                ) : conteudo?.tipoAnexo?.codigo == 4 ? (
                    <Styled.ButtonPDF disabled={loadingDownload} onClick={downloadPdf}>
                        <img src={IconDownload} alt="" />
                        {loadingDownload ? 'Baixando...' : 'Baixar material'}
                    </Styled.ButtonPDF>
                ) : (
                    <></>
                )}
            </>
        );
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            fetchFavorite();
        } else {
            const route = location.pathname;
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + route,
            };
            const _url =
                item?.tipoRedirecionamento === TipoRedirecionamento.CADASTRO
                    ? keycloak.createRegisterUrl(options)
                    : keycloak.createLoginUrl(options);
            window.location.assign(_url);
        }
        fetchComments();
        porcentagem();
        registerFirstAccess();
    }, [conteudo]);

    useEffect(() => {
        if (conteudo && !blockListenConteudo) {
            if (!conteudo.isConcluido) {
                timePedding();
            } else {
                setPeddingLoading(false);
            }

            registerAction(LogAcaoRecursoEnum.ACESSO_CONTEUDO);
        }
    }, [conteudo, blockListenConteudo]);

    useEffect(() => {
        if (!loadingConteudo) {
            setLoadingRecurso(-1);
        }
    }, [loadingConteudo]);

    const buttons = (progress: boolean) => {
        return (
            <div>
                {!conteudo?.isConcluido ? (
                    <Styled.ButtonContainer>
                        {isLast() ? (
                            <Styled.MarkButton
                                disabled={loadingSave || loadingItem || isBlockConcluirConteudo || peddingLoading}
                                onClick={markDone}
                            >
                                {peddingLoading ? <div className="loader01">Aguarde...</div> : 'Concluir'}
                            </Styled.MarkButton>
                        ) : (
                            <>
                                <Styled.MarkButton
                                    style={{ width: '60%', marginRight: '5%' }}
                                    disabled={loadingSave || loadingItem || peddingLoading}
                                    onClick={markDone}
                                >
                                    {peddingLoading ? <div className="loader01">Aguarde...</div> : 'Concluir'}
                                </Styled.MarkButton>
                                <Styled.MarkButton
                                    disabled={loadingSave || loadingItem || isBlockConcluirConteudo || peddingLoading}
                                    onClick={markDoneAndNext}
                                >
                                    {peddingLoading ? <div className="loader01">Aguarde...</div> : 'Concluir e avançar'}
                                </Styled.MarkButton>
                            </>
                        )}
                    </Styled.ButtonContainer>
                ) : (
                    <Styled.ButtonContainer>
                        {isLast() ? (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <Styled.DoneButton style={{ width: '60%', marginRight: '5%', marginLeft: '5%' }}>
                                    {!loadingSave && <BsCheckCircle />}
                                    <span>{loadingSave ? 'Concluíndo...' : 'Concluído'}</span>
                                </Styled.DoneButton>
                                <Styled.AvancarButton
                                    onClick={() => gotoBackLevel()}
                                    style={{ width: '60%', marginRight: '5%', marginLeft: '5%' }}
                                >
                                    <span>Avançar</span>
                                </Styled.AvancarButton>
                            </div>
                        ) : (
                            <>
                                <Styled.DoneButton style={{ width: '60%', marginRight: '5%' }}>
                                    {!loadingSave && <BsCheckCircle />}
                                    <span>{loadingSave ? 'Concluíndo...' : 'Concluído'}</span>
                                </Styled.DoneButton>
                                <Styled.MarkButton disabled={loadingConteudo || peddingLoading} onClick={() => gotoNext()}>
                                    {'Próximo Conteúdo'}
                                </Styled.MarkButton>
                            </>
                        )}
                    </Styled.ButtonContainer>
                )}
                {progress && (
                    <>
                        <Styled.TextProgress>{'Progresso'}</Styled.TextProgress>
                        <BarProgressContent value={porcentagem()} />
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            {contextHolder}
            <RecursosRateModal idConteudo={conteudo?.codigo} show={showModal} onHide={() => setShowModal(false)} prerate={null} />
            <ModalPlayAudio handleStatus={(s: boolean) => setNescessityPlayAudio(s)} />
            <Toaster />
            <Styled.Container>
                <>
                    <PrimaryButton className="desk-only" onClick={gotoBackLevel}>
                        <IoIosArrowBack />
                        <Styled.Buttontext>Voltar</Styled.Buttontext>
                    </PrimaryButton>
                    <div className="mobile-only">
                        <Row style={{ margin: '0 10px' }}>
                            <Styled.RoundPrimaryButton onClick={gotoBackLevel}>
                                <IoIosArrowBack />
                            </Styled.RoundPrimaryButton>

                            <Styled.SpaceHorizontal />

                            {keycloak.authenticated && (
                                <>
                                    <Styled.SquareButton onClick={favorite}>
                                        {!favorited ? <AiOutlineHeart /> : <AiFillHeart />}
                                    </Styled.SquareButton>

                                    <Styled.SquareButton onClick={() => setShowModal(true)} style={{ margin: '0 15px' }}>
                                        <BsEmojiSmile />
                                    </Styled.SquareButton>
                                </>
                            )}

                            <Styled.SquareButton onClick={copyToClipboard}>
                                <AiOutlineShareAlt />
                            </Styled.SquareButton>
                        </Row>
                    </div>

                    {<Styled.Title>{conteudo?.nome}</Styled.Title>}

                    <Row alignVertical="start">
                        <Styled.ColumnContent style={{ maxWidth: '1620px' }}>
                            {getAnexoPrincipal(conteudo?.positionArquivoPrincipal as ArquivoPrincipalEnum, ArquivoPrincipalEnum.INICIO)}

                            <div className="desk-only">
                                <Row>
                                    {keycloak.authenticated && (
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip id={'tooltip-fav'}>{'Favoritar'}</Tooltip>}>
                                            <Styled.SquareButton onClick={favorite}>
                                                {!favorited ? (
                                                    <>
                                                        <AiOutlineHeart />
                                                        <label>{'Favoritar'}</label>
                                                    </>
                                                ) : (
                                                    <>
                                                        <AiFillHeart />
                                                        <label>{'Favorito'}</label>
                                                    </>
                                                )}
                                            </Styled.SquareButton>
                                        </OverlayTrigger>
                                    )}

                                    <Styled.SpaceHorizontal />

                                    {keycloak.authenticated && (
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip id={'tooltip-rate'}>{'Avaliar'}</Tooltip>}>
                                            <Styled.SquareButton onClick={() => setShowModal(true)} style={{ margin: '0 15px' }}>
                                                <BsEmojiSmile />
                                                <label>{'Avaliar'}</label>
                                            </Styled.SquareButton>
                                        </OverlayTrigger>
                                    )}

                                    <OverlayTrigger placement={'top'} overlay={<Tooltip id={'tooltip-share'}>{'Compartilhar'}</Tooltip>}>
                                        <Styled.SquareButton style={{ width: '100px' }} onClick={copyToClipboard}>
                                            <AiOutlineShareAlt />
                                            <label>{'Compartilhar'}</label>
                                        </Styled.SquareButton>
                                    </OverlayTrigger>
                                </Row>
                            </div>

                            <Styled.ContentNav>
                                <Row>
                                    {keycloak.authenticated && (
                                        <>
                                            {!conteudo?.isConcluido ? (
                                                <Styled.MarkButton
                                                    style={{ width: '60%' }}
                                                    disabled={loadingSave || loadingItem}
                                                    onClick={markDone}
                                                >
                                                    {loadingSave ? 'Concluíndo...' : 'Marcar como concluído'}
                                                </Styled.MarkButton>
                                            ) : (
                                                <Styled.DoneButton style={{ width: '60%' }}>
                                                    <BsCheckCircle />
                                                    <span>Concluído</span>
                                                </Styled.DoneButton>
                                            )}
                                        </>
                                    )}

                                    <Styled.SpaceHorizontal />
                                    {!isFirst() && (
                                        <Styled.NextButton onClick={gotoPrev}>
                                            <IoIosArrowBack />
                                        </Styled.NextButton>
                                    )}
                                    {!isLast() && (
                                        <Styled.NextButton onClick={() => gotoNext()} style={{ marginLeft: '20px' }}>
                                            <IoIosArrowForward />
                                        </Styled.NextButton>
                                    )}
                                </Row>
                            </Styled.ContentNav>

                            {isNescessityPlayAudio && <div id="audimaWidget"></div>}

                            <Styled.TextContent
                                onClick={e => {
                                    const target = e.target as HTMLImageElement;
                                    if (target.tagName === 'IMG') {
                                        openModal(target.src);
                                    }
                                }}
                                onMouseOver={e => {
                                    const target = e.target as HTMLImageElement;
                                    if (target.tagName === 'IMG') {
                                    }
                                }}
                                dangerouslySetInnerHTML={{ __html: conteudo?.texto ?? '' }}
                            />
                            <AmpliarImg show={modalOpen} onClose={closeModal} porcent={porcent} setPorcent={setPorcent} imgSrc={currentImgSrc} />

                            {conteudo &&
                                conteudo.componentes.length > 0 &&
                                conteudo.componentes
                                    .sort((a, b) => a.ordem - b.ordem)
                                    .map((dados, index) => {
                                        if (dados.tipo === ComponentInteractionEnum.AUDIO) {
                                            return (
                                                <>
                                                    <RecursoMediaSwitch key={index} anexoUrl={dados.conteudo} />
                                                </>
                                            );
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.TEXT) {
                                            return (
                                                <Styled.TextContent
                                                    style={{ margin: '20, 0', backgroundColor: dados.cor ?? '', padding: '22px 10px' }}
                                                    key={index}
                                                    onClick={e => {
                                                        const target = e.target as HTMLImageElement;
                                                        if (target.tagName === 'IMG') {
                                                            openModal(target.src);
                                                        }
                                                    }}
                                                    onMouseOver={e => {
                                                        const target = e.target as HTMLImageElement;
                                                        if (target.tagName === 'IMG') {
                                                        }
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: dados.conteudo ?? '' }}
                                                />
                                            );
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.SANFONA) {
                                            return (
                                                <ConfigProvider
                                                    key={index}
                                                    collapse={{
                                                        style: {
                                                            backgroundColor: dados.cor ?? '',
                                                            marginBottom: '20px',
                                                        },
                                                    }}
                                                >
                                                    <Collapse
                                                        size="large"
                                                        items={dados.sanfona?.map((sanfona, idxSanfona) => ({
                                                            key: idxSanfona,
                                                            label: (
                                                                <h1
                                                                    style={{
                                                                        color:
                                                                            dados.cor === Colors.Blue || dados.cor === Colors.SoftBlue
                                                                                ? Colors.White
                                                                                : Colors.Blue,
                                                                        fontWeight: 700,
                                                                    }}
                                                                >
                                                                    {sanfona.titulo}
                                                                </h1>
                                                            ),
                                                            children: (
                                                                <>
                                                                    {sanfona.componentSanfonaConteudos?.map((conteudo, idxConteudo) => {
                                                                        if (conteudo.tipoConteudo === ComponentInteractionEnum.TEXT) {
                                                                            return (
                                                                                <Styled.TextContent
                                                                                    style={{ margin: 0 }}
                                                                                    key={idxConteudo}
                                                                                    onClick={e => {
                                                                                        const target = e.target as HTMLImageElement;
                                                                                        if (target.tagName === 'IMG') {
                                                                                            openModal(target.src);
                                                                                        }
                                                                                    }}
                                                                                    onMouseOver={e => {
                                                                                        const target = e.target as HTMLImageElement;
                                                                                        if (target.tagName === 'IMG') {
                                                                                        }
                                                                                    }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: conteudo.conteudo ?? '',
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }

                                                                        if (conteudo.tipoConteudo === ComponentInteractionEnum.AUDIO) {
                                                                            return (
                                                                                <RecursoMediaSwitch
                                                                                    key={idxConteudo}
                                                                                    anexoUrl={conteudo.conteudo}
                                                                                />
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            ),
                                                        }))}
                                                        onChange={onChange}
                                                        expandIcon={({ isActive }) => (
                                                            <StyledCaretRightOutlined
                                                                style={{
                                                                    color:
                                                                        dados.cor === Colors.Blue || dados.cor === Colors.SoftBlue
                                                                            ? Colors.White
                                                                            : Colors.Blue,
                                                                }}
                                                                size={22}
                                                                rotate={isActive ? 90 : 0}
                                                            />
                                                        )}
                                                    />
                                                </ConfigProvider>
                                            );
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.QUIZ) {
                                            return (
                                                <>
                                                    <QuizInteractionPublic
                                                        key={index}
                                                        quizzes={dados.quiz ?? []}
                                                        componente={dados}
                                                        openModal={openModal}
                                                        setBlockConcluirConteudo={b => {
                                                            if (!conteudo.isConcluido) {
                                                                setBlockConcluirConteudo(b);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            );
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.CORRESPONDENT) {
                                            return <CorrespondentPublic key={index} dados={dados.correspondent} />;
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.CARROSSEL) {
                                            return <CarrosselInteractionPublic key={index} dados={dados.carrossel} />;
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.ABAS) {
                                            return <ComponentAbasPublic key={index} dados={dados.abas} openModal={openModal} />;
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.CARD_BARALHO) {
                                            return (
                                                <ComponentBaralhoPublic
                                                    key={index}
                                                    dados={dados.baralho ?? []}
                                                    quantityVisible={dados.quantityVisible}
                                                />
                                            );
                                        }

                                        if (dados.tipo === ComponentInteractionEnum.REGUA_AVALIATIVA && dados.reguaAvaliativa) {
                                            return <ReguaAvaliativaPublic key={index} dados={dados.reguaAvaliativa} />;
                                        }
                                        
                                        if (dados.tipo === ComponentInteractionEnum.TIMELINE && dados.timelines) {
                                            return (
                                                <ComponentTimeLinesPublic key={index} dados={dados.timelines} color={dados.cor ?? ''} />
                                            );
                                        }
                                        if (dados.tipo === ComponentInteractionEnum.QUIZ_PONTUACAO && dados.quizPontuacao) {
                                            return <ComponentQuizPontucaoPublic key={index} dados={dados.quizPontuacao} />;
                                        }
                                        if (dados.tipo === ComponentInteractionEnum.CENARIO && dados.cenarios) {
                                            return <ComponentCenarioPublic key={index} dados={dados.cenarios} />;
                                        }
                                    })}

                            {getAnexoPrincipal(conteudo?.positionArquivoPrincipal as ArquivoPrincipalEnum, ArquivoPrincipalEnum.FINAL)}

                            {keycloak.authenticated && buttons(false)}

                            <Styled.Container>
                                {comments?.length > 0 && <Styled.Label>Comentários</Styled.Label>}
                                <br />
                                <Styled.CommentBox isDropped={isDropped}>
                                    {comments?.map((comment: ComentariosRecursosCostumizados) => (
                                        <RecursosComment
                                            key={comment.codigo}
                                            comment={comment}
                                            answers={comment.respostas ?? []}
                                            onExclude={() => fetchComments()}
                                            onEdited={() => fetchComments()}
                                        />
                                    ))}
                                    {(comments?.length > 2 ||
                                        (comments?.length > 1 && (comments[0].comentario.length > 0 || comments[1].comentario.length > 0))) &&
                                    !isDropped ? (
                                        <Styled.SeeMoreButton
                                            isDropped={isDropped}
                                            onClick={() => {
                                                setDropped(!isDropped);
                                            }}
                                        >
                                            <Styled.Icon src={PlusCircle} />
                                            {'Ler mais'}
                                        </Styled.SeeMoreButton>
                                    ) : null}
                                </Styled.CommentBox>

                                {keycloak.authenticated && (
                                    <>
                                        <Styled.Label>Deixe seu comentário</Styled.Label>
                                        {isPalavrasProibidas && (
                                            <p style={{ color: Colors.InfoRed, marginTop: '10px' }}>
                                                Para publicar seu comentário, remova todas as palavras proibidas que estão presentes nele.
                                            </p>
                                        )}
                                        <br />
                                        <br />
                                        <Styled.FormControl
                                            as="textarea"
                                            rows={3}
                                            onChange={evt => {
                                                const value = evt.target.value;
                                                if (value.length >= 300) toast.error('Comentário muito longo');
                                                else setCommentInput(value);
                                            }}
                                            maxLength={300}
                                            value={commentInput}
                                        />
                                        <Styled.AlignRight>
                                            <Styled.SendComentary
                                                onClick={() => {
                                                    toSendComment();
                                                }}
                                                disabled={(!isValidComment() && !loading) || isPalavrasProibidas}
                                            >
                                                {loading ? 'Publicando' : 'Publicar'}
                                            </Styled.SendComentary>
                                        </Styled.AlignRight>
                                    </>
                                )}
                                <br />
                                <br />
                            </Styled.Container>
                        </Styled.ColumnContent>

                        <Styled.Gap />

                        <Styled.ColumnContent className="desk-only">
                            <Styled.PlaylistContainer>
                                {conteudos
                                    ?.sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                                    .map(c => {
                                        return (
                                            <Styled.PlaylistItem
                                                active={c.codigo == conteudo?.codigo}
                                                key={c.codigo}
                                                onClick={() => gotoContent(c)}
                                            >
                                                {numberLoadingRecurso !== c.codigo ? (
                                                    <Styled.PlaylistItemCheck show={c.isConcluido}>
                                                        <BsCheck2Circle />
                                                    </Styled.PlaylistItemCheck>
                                                ) : (
                                                    <div className="loaderConteudo" />
                                                )}
                                                <span>{c.nome}</span>
                                            </Styled.PlaylistItem>
                                        );
                                    })}
                            </Styled.PlaylistContainer>

                            {keycloak.authenticated && buttons(true)}

                            <Row justify="end" style={{ marginTop: '10px' }}>
                                {!isFirst() && (
                                    <OverlayTrigger
                                        delay={800}
                                        placement={'top'}
                                        overlay={<Tooltip id={'tooltip-fav'}>{'Conteúdo Anterior'}</Tooltip>}
                                    >
                                        <Styled.NextButton onClick={gotoPrev}>
                                            <IoIosArrowBack />
                                        </Styled.NextButton>
                                    </OverlayTrigger>
                                )}

                                {!isLast() && (
                                    <OverlayTrigger
                                        delay={800}
                                        placement={'top'}
                                        overlay={<Tooltip id={'tooltip-fav'}>{'Próximo conteúdo'}</Tooltip>}
                                    >
                                        <Styled.NextButton
                                            disabled={isBlockConcluirConteudo}
                                            onClick={() => gotoNext()}
                                            style={{ marginLeft: '20px' }}
                                        >
                                            <IoIosArrowForward />
                                        </Styled.NextButton>
                                    </OverlayTrigger>
                                )}
                            </Row>
                        </Styled.ColumnContent>
                    </Row>
                </>
            </Styled.Container>
        </>
    );
};

export default RecursoConteudoPage;
