import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import ComponentQuizPontuacaoFeedback from './ComponentQuizPontuacaoFeedback';
import ComponentQuizPontuacaoAlternativas from './ComponentQuizPontuacaoAlternativas';

interface IComponentQuizPontuacao {
    id: number;
    title: string;
    description: string;
    alternativas: ComponentQuizPontuacaoAlternativas[];
    multipleChoice: boolean;
}

class ComponentQuizPontuacao extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(Boolean)
    multipleChoice: boolean = false;

    alternativas: ComponentQuizPontuacaoAlternativas[] = [];

    fromJSON(json: IComponentQuizPontuacao, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.title = json.title;
        this.description = json.description;
        this.multipleChoice = json.multipleChoice;

        if (Array.isArray(json.alternativas)) {
            this.alternativas = json.alternativas.map(alt => new ComponentQuizPontuacaoAlternativas().fromJSON(alt));
        } else {
            this.alternativas = [];
        }

        return this;
    }
}

export default ComponentQuizPontuacao;
