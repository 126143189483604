import { isUndefined } from 'lodash';
import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IComponentQuizPontuacaoFeedback {
    id: number;
    nivelMin: number;
    nivelMax: number;
    text: string;
}

class ComponentQuizPontuacaoFeedback extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(Number)
    nivelMin: number = -1;

    @jsonProperty(Number)
    nivelMax: number = -1;

    @jsonProperty(String)
    text: string = '';

    fromJSON(json: IComponentQuizPontuacaoFeedback, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.nivelMin = json.nivelMin;
        this.nivelMax = json.nivelMax;
        this.text = json.text;

        return this;
    }
}

export default ComponentQuizPontuacaoFeedback;
